import { useEffect, useState } from "react";
import { getClasses } from "../constants/theme";
import { Settings } from "../stores/Settings";
import { getClientTitle, makeImageUrl } from "../utils";


export default function Avatar(props) {
    const classes = getClasses(dynamicStyle);
    const imgMode = props.clId?.img_id &&  !!props.clId?.img_id[0]?._id;
    const size = props.size;
    const borderRadius = Math.round(props.size / 2);
    const fontSize = Math.round(props.size / 2.3);


    return <div style={{
        width: size,
        height: size,
        borderRadius: borderRadius,
    }} className={classes('container')}
        onClick={props.onClick}
    >
        {imgMode ? (
            <img
                src={makeImageUrl(props.clId?.img_id?.[0])}
                className={classes('image')}
                crossorigin="anonymous"
                style={{
                    width: size,
                    height: size,
                    borderRadius: borderRadius,
                }} />
        ) : (
            <div style={{ fontSize }} className={classes('text')}>
                {getClientTitle(props.clId)
                    .split(' ')
                    .map(w => w[0])
                    .join('')}
            </div>
        )}
    </div>
}


const dynamicStyle = (Colors, Fonts, params = {}) => {
    return {
        blockedContainer: {
            backgroundColor: Colors.neutral4,
        },
        lockIcon: {
            color: Colors.neutral3,
            fontSize: 24,
        },
        badgeIcon: {
            color: Colors.white,
            fontSize: 14,
        },
        badge: {
            width: 16,
            height: 16,
            backgroundColor: Colors.color1,
            position: 'absolute',
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center',
            right: -2,
            top: -2,
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.color1,
        },
        text: {
            ...Fonts.title1,
            lineHeight: void 0,
            color: Colors.neutral5,
        },
        image: {},
    };
};
