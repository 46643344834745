import {Store} from './core/store';

export const Settings = new Store({
  key: 'store:settings-2',
  defaultSchema: {
    theme: 'system',
    system_theme: 'system',
    mode:'org',
    authorized: false, // приложение загружено и авторизировано
    'use-system-locale': true,
    server:{
     proxy_host: 'https://proxy.udeu.ru/v2/',
     host: 'https://proxy.udeu.ru/v2',
     cdn: 'https://storage.yandexcloud.net/',
      cdn_type: 'yandex',
     bucket: 'udeu',

      // proxy_host: 'https://ev-proxy.getbackdev.com/v2/',
      // host: 'https://ev.getbackdev.com/v2',
      // cdn: 'https://storage.googleapis.com/',
      // cdn_type: 'gcs',
      // bucket: 'marathon-temp',
    },
  },
});

window.Settings = Settings;
