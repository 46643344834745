import { useTheme } from "../constants/theme";

export default function Button(props) {
  const styles = useTheme(dynamicStyle);

  return (
    <div
      onClick={props.onClick}
      style={{ ...styles.container, ...(props.containerStyle || {}) }}
    >
      {props.icon}
      {props.text}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    container: {
      ...Fonts.headline1,
      color: Colors.white,
      backgroundColor: Colors.color1,
      display: "flex",
      justifyContent: "center",
      paddingTop: 16,
      paddingBottom: 16,
      borderRadius: 16,
      cursor: "pointer",
    },
  };
};
