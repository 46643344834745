
import React, { useState, useEffect, useImperativeHandle, useRef, useCallback, useReducer } from "react";
import { useTheme } from "../constants/theme";
import { Settings } from "../stores/Settings";
import 'react-image-crop/dist/ReactCrop.css'
import Button from "./Button";
import { Pause, Play, X } from "react-feather";
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg/dist/ffmpeg.min.js';
import { mmss } from "../utils";



const initialState = {
    selected: false,
    move: false,
    x: -1000
};

function reducer(state, action) {
    switch (action.type) {
        case 'select':
            return { ...state, selected: action.selected };
        case 'statrMove':
            return { ...state, move: action.move };
        case 'changePosition':
            return { ...state, x: action.x };
        case 'reset':
            return { ...initialState };
        default:
            throw new Error();
    }
}


let ffmpeg;


export default function VideoTrimForm(props) {
    const styles = useTheme(dynamicStyle);
    const [file, setFile] = useState(void 0);
    const videoRef = useRef();
    const trimRef = useRef();
    const timelineArea = useRef();

    const [played, setPlayed] = useState(false);
    const [time, setTime] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [trim, setTrim] = useState(0);

    const [balloon1, balloon1Dispatch] = useReducer(reducer, initialState);
    const [balloon2, balloon2Dispatch] = useReducer(reducer, initialState);

    const refBalloon1 = useRef();
    const refBalloon2 = useRef();

    const refBalloon1X = useRef(0);
    const refBalloon2X = useRef(0);

    const [trimProgress, setProgress] = useState(void 0)



    useEffect(() => {
        return Settings.on('trimThisFile', f => {
            setFile(f)
        });


    }, []);

    const close = () => {
        Settings.emit(`closeTrimFile-${file.id}`, {
            event: 'close'
        });


        reset();

        setFile(void 0);
    }

    const reset = () => {
        const coordsContainer = getCoords(trimRef.current);

        timelineArea.current.style.marginLeft = `0px`;
        timelineArea.current.style.width = `${coordsContainer.width}px`;

        refBalloon1.current.style.marginLeft = `0px`;
        refBalloon2.current.style.marginLeft = `${coordsContainer.width}px`;

        refBalloon1X.current = 0;
        refBalloon2X.current = coordsContainer.width;

        setPlayed(false);
        setTime(0);
        setCurrentTime(0);

        
    }

    const save = () => {

        Settings.emit(`closeFile-${file.id}`, {
            event: 'save',
            file: file.original
        });

        close();
    }

    const togglePlay = () => {
        if (!played) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
    }

    const clickToSeek = (event) => {
        event.preventDefault();
        let target = event.target;

        if (event.target.id !== 'trimbarc' && event.target.id !== 'trimbar' && event.target.id !== 'timeline')
            return;

        if (target.id === 'timeline') {
            target = target.parentElement
        }



        var rect = target.getBoundingClientRect();
        var x = event.clientX - rect.left; //x position within the element.


        const trimrect = trimRef.current.getBoundingClientRect();

        const p = x / trimrect.width * 100;
        const s = p * videoRef.current.duration / 100;

        videoRef.current.currentTime = s;
    }


    const calcTimeStamp = (x, w) => {
        const p = x / w * 100;
        const t = time * p / 100;

        return t;
    }


    const moveAt = (event, element, coordsContainer, left) => {
        let x = event.pageX - coordsContainer.left

        if (left) {
            if (x >= refBalloon2X.current - 40) {
                x = refBalloon2X.current - 40;
            }

            if (x <= 0) {
                x = 0
            }

            refBalloon1X.current = x;

            const t = calcTimeStamp(x, coordsContainer.width);

            //if (t> currentTime){
            //videoRef.current.currentTime = t;
            // }

        } else {
            if (x < refBalloon1X.current + 40) {
                x = refBalloon1X.current + 40;
            }

            if (x >= coordsContainer.width) {
                x = coordsContainer.width;
            }

            refBalloon2X.current = x;
        }


        const rOffset = coordsContainer.width - refBalloon1X.current - (coordsContainer.width - refBalloon2X.current)


        timelineArea.current.style.marginLeft = `${refBalloon1X.current}px`;
        timelineArea.current.style.width = `${rOffset}px`;

        element.style.marginLeft = `${x}px`;
    }

    const getCoords = (elem) => {   // кроме IE8-
        var box = elem.getBoundingClientRect();
        return {
            left: box.left,
            right: box.right,
            width: box.width,
            height: box.height
        };
    }

    const onDragStart = (event, dispatch, balloon, ref, left = false) => {
        event.preventDefault();
        event.stopPropagation();
        // coords = getCoords(ref.current);
        //  var shiftX = event.pageX - coords.left;
        const coordsContainer = getCoords(trimRef.current);


        dispatch({ type: 'select', selected: true })

        document.onmousemove = function (e) {
            moveAt(e, ref.current, coordsContainer, left);
        };

        document.onmouseup = function (e) {
            e.stopPropagation();

            dispatch({ type: 'select', selected: false });
            document.onmousemove = null;
            document.onmouseup = null;

            return false;
        };

        return false;

    }

    const transcode = async () => {
        const coordsContainer = getCoords(trimRef.current);
        const start = calcTimeStamp(refBalloon1X.current, coordsContainer.width);
        const end = calcTimeStamp(refBalloon2X.current, coordsContainer.width);



        if (!ffmpeg) {
            ffmpeg = createFFmpeg({
                log: false,
                progress: trimProgressHandler
            });
            await ffmpeg.load();
        }

        const name = 'test.avi';

        ffmpeg.FS('writeFile', name, await fetchFile(file.original));


        await ffmpeg.run('-i', name, '-ss', `${start}`, '-to', `${end}`, 'output.mp4');

        const data = ffmpeg.FS('readFile', 'output.mp4');
        const original = new Blob([data.buffer], { type: 'video/mp4' });

        ffmpeg.FS("unlink", 'output.mp4');

        setFile({
            ...file,
            original,
            file: URL.createObjectURL(original)
        })
    }

    const trimProgressHandler = (progress) => {
        const coordsContainer = getCoords(trimRef.current);
        const start = calcTimeStamp(refBalloon1X.current, coordsContainer.width);
        const end = calcTimeStamp(refBalloon2X.current, coordsContainer.width);
        const p = time - start - (time - end)

        if (progress.ratio === 1) {
            setProgress(void 0);
        } else {
            setProgress({ ...progress, progress: progress.time / p * 100 });
        }
    }


    const init = (event) => {
        reset();
        setTime(videoRef.current.duration)
    }


    if (!file)
        return null;

    const progress = currentTime / time * 100

    return <>
        <div style={styles.container}>
            {/* <div style={styles.overlay}></div> */}
            <div style={styles.form}>
                <div style={styles.body}>
                    <div style={styles.crop}>
                        <div style={styles.videoWrapper} >
                            <video
                                play={played}
                                //controls={true}
                                onPlay={() => {
                                    setPlayed(true);
                                }}
                                onPause={() => {
                                    setPlayed(false);
                                }}
                                onLoadedMetadata={(event) => {
                                    init(event)
                                }}
                                onTimeUpdate={(event) => {
                                    setCurrentTime(videoRef.current.currentTime)
                                }}
                                crossorigin="anonymous"
                                ref={videoRef} style={styles.video} src={file.file} type={file.original.type} />
                        </div>
                        <div style={styles.controllWrapper}>
                            <div style={styles.control}>
                                <div style={styles.play} onClick={togglePlay}>
                                    {!played ? <Play size={32} color={styles.colors.color2} /> : <Pause size={32} color={styles.colors.color2} />}
                                </div>
                                <div style={styles.trimbar} ref={trimRef} id={'trimbar'} onClick={clickToSeek}>
                                    <div style={styles.balloon} ref={refBalloon1}>
                                        <div style={{ ...styles.balloonHeader, marginLeft: 8 }}
                                            draggable={true}
                                            onDragStart={(e) => { onDragStart(e, balloon1Dispatch, balloon1, refBalloon1, true) }}
                                        >
                                            <Leftbaloon />
                                        </div>
                                        <div style={styles.balloonBody}></div>
                                    </div>

                                    <div style={styles.balloon} ref={refBalloon2}>
                                        <div style={{ ...styles.balloonHeader, marginRight: 30 }}
                                            draggable={true}
                                            onDragStart={(e) => { onDragStart(e, balloon2Dispatch, balloon2, refBalloon2) }}
                                        >
                                            <Rightbaloon />
                                        </div>
                                        <div style={styles.balloonBody}></div>
                                    </div>
                                    <div id={'timeline'} ref={timelineArea} style={styles.timeline}></div>


                                    <div id={'trimbarc'} style={{ ...styles.currentPlay, width: `${progress}%` }}>
                                        <div style={styles.playHolder}></div>
                                    </div>
                                    <div style={styles.timepoints}>
                                        <div style={styles.timepoint}>0</div>
                                        <div style={styles.timepoint}>{mmss(time / 2 * 1000)}</div>
                                        <div style={styles.timepoint}>{mmss(time * 1000)}</div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={styles.menu}>
                        <div style={styles.innerMenu}>
                            <div style={styles.action}>
                                <div style={styles.title}>Загрузка видео</div>
                                <div style={styles.subtitle}>Действия</div>


                                <Button
                                    text={"Обрезать"}
                                    containerStyle={styles.selectedButton}
                                    onClick={transcode}

                                />
                                <Button
                                    containerStyle={styles.button}
                                    text={"Сбросить"}
                                    onClick={reset}

                                />
                            </div>

                            <div>
                                <Button
                                    onClick={save}
                                    text={"Сохранить"}
                                    containerStyle={styles.selectedButton}
                                />
                                <Button
                                    onClick={close}
                                    text={"Отменить"}
                                    containerStyle={styles.button}
                                />
                            </div>


                        </div>
                    </div>
                </div>




            </div>
        </div>
        {trimProgress !== void 0 && (
            <div style={styles.processingOverlay}>
                <div style={styles.processing}>
                    {~~trimProgress.progress} %
                </div>
            </div>)}
    </>
}


const Leftbaloon = () => {
    return <svg width="22" height="40" viewBox="0 0 22 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 0L2 20.3077V40H0V0H22Z" fill="#00CE70" />
    </svg>
}

const Rightbaloon = () => {
    return <svg width="22" height="40" viewBox="0 0 22 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0L20 20V40H22V0H0Z" fill="#00CE70" />
    </svg>

}


const dynamicStyle = (Colors, Fonts) => {
    return {
        processingOverlay: {
            display: 'flex',
            flex: 1,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            backgroundColor: '#000000af',
            zIndex: 100
        },
        processing: {
            height: 60,
            width: 100,
            backgroundColor: '#000',
            marginRight: 270,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 16,
            '-webkit-box-shadow': '0px 0px 14px 2px rgba(34, 60, 80, 0.49)',
            '-moz-box-shadow': '0px 0px 14px 2px rgba(34, 60, 80, 0.49)',
            'box-shadow': '0px 0px 14px 2px rgba(34, 60, 80, 0.49)',
            ...Fonts.subhead2,
            color: '#fff'

        },
        timepoints: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginTop: -25,
            // marginLeft: -6

        },
        timepoint: {
            ...Fonts.caption,
            color: Colors.neutral2
        },
        colors: Colors,
        timeline: {
            height: 20,
            backgroundColor: '#00ce7094',
            width: 0,
            position: 'absolute'

        },
        balloon: {
            position: 'absolute',
            // width: 4,
            //height: 70,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: -20
        },
        balloonHeader: {
            width: 16,
            height: 16,
            position: 'absolute',
            cursor: 'pointer'
            // backgroundColor: '#f00',
            // borderRadius: 8,
            //: 'pointer'
        },
        balloonBody: {
            width: 4,
            height: 60,
            // backgroundColor: '#f00'
        },
        playHolder: {
            width: 2,
            height: 50,
            backgroundColor: '#fff',
            position: 'absolute',
            cursor: 'pointer',
            marginTop: -23
        },
        currentPlay: {
            height: 50,
            display: 'flex',
            justifyContent: 'flex-end'
        },
        time: {
            height: 50,
            width: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...Fonts.footnote,
            color: '#fff',
            backgroundColor: '#000',

        },
        videoWrapper: {
            display: 'flex',
            flex: 1,
            alignItems: 'center'
        },

        controllWrapper: {
            width: '100%',
            display: 'flex',
        },
        control: {
            backgroundColor: '#060606',
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            marginLeft: 60,
            marginRight: 60,
            marginBottom: 32,
            paddingLeft: 14,
            paddingRight: 14,
            paddingTop: 18,
            paddingBottom: 18,
            borderRadius: 16

        },
        play: {
            height: 64,
            width: 64,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        trimbar: {
            backgroundImage: 'url("/lines.png")',
            backgroundColor: '#26855A',
            height: 20,
            width: '100%',
            marginLeft: 24,
            marginRight: 24,

        },
        video: {
            width: '90%',
            height: 500
            // height: '100%',

        },
        selectedButton: {
            ...Fonts.headline1,
            color: '#000',
            backgroundColor: Colors.color1,
            display: "flex",
            justifyContent: "center",
            paddingTop: 16,
            paddingBottom: 16,
            borderRadius: 16,
            cursor: "pointer",
            marginTop: 8

        },
        button: {
            color: Colors.color1,
            backgroundColor: 'transparent',
            borderWidth: 2,
            borderColor: Colors.color1,
            borderStyle: 'solid',
            marginTop: 8
        },
        title: {
            ...Fonts.title2,
            color: Colors.neutral5,
            marginBottom: 16
        },
        subtitle: {
            ...Fonts.subhead2,
            color: Colors.neutral5
        },
        action: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column'

        },
        crop: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
            //padding: 16
        },
        menu: {
            width: 270,
            height: '100%',
            backgroundColor: '#000',
            display: 'flex',
            flexDirection: 'column',
        },
        innerMenu: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: 32,
        },
        body: {
            display: 'flex',
            flex: 1
        },
        close: {
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
            fontSize: 14,
            backgroundColor: Colors.accent2,
            marginRight: 12
        },
        save: {
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
            fontSize: 14,
        },
        header: {
            ...Fonts.headline1,
            marginBottom: 6,
            display: 'flex',
            justifyContent: 'space-between'
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginTop: 10
        },
        image: {
            width: '100%',
            height: '100vh',
        },
        container: {
            position: 'fixed',
            zIndex: 1,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: 'flex',
            flex: 1,
            // justifyContent: 'center',
            // alignItems: 'center'
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: '#000000af',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 2,
            backgroundColor: '#000000af',
            // padding: '12px 16px',
            flex: 1,
        },
    }
}