import React, { useState, useRef } from "react";
import { useTheme } from "../../constants/theme";

export default function TextInput({
  collector,
  name,
  label,
  multiline,
  disabled,
  hr,
  onTestValue,
  containerStyle = {},
  containerRef,
  ...props
}) {
  const [text, setText] = useState(() => collector.get(name));
  const [error, setError] = useState();
  const inputRef = useRef();

  collector.resetCallback(name, (value) => setText(value));
  collector.errorCallback(name, (value) => setError(value));

  const styles = useTheme(dynamicStyle);

  return (
    <div
      style={{
        ...styles.container,
        ...containerStyle,

      }}
    >
      <input
        {...props}
        ref={inputRef}
        onChange={(event) =>
          collector.set(name, event.target.value, setText, onTestValue)
        }
        value={text}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        style={{
          ...styles.input,
          ...(!!disabled ? styles.disabled : {}),
          ...props.inputStyle,
          ...(error != null ? styles.error : {}),

        }}
      />
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    container: {
      flexDirection: "row",
      backgroundColor: Colors.neutral4,
      borderRadius: 10,
      paddingHorizontal: 16,
      display: "flex",
    },
    input: {
      ...Fonts.subhead1,
      padding: 0,
      width: "100%",
      minHeight: 44,
      justifyContent: "center",
      color: Colors.neutral1,
      backgroundColor: Colors.neutral4,
      borderWidth: 0,
      borderRadius: 10,
      paddingLeft: 16,
      paddingRight: 16,
      display: "flex",
      outlineColor: Colors.neutral3,
    },
    disabled: {
      color: Colors.neutral2,
    },
    error: {
      backgroundColor: Colors.accent3,
      outline: `1px solid ${Colors.accent2}`
    },
  };
};
