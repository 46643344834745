import { useNavigate } from "react-router-dom";
import InfinityList from "../../../components/InfinityList";
import DraftItem from "../components/DraftItem";
import { getMyCourses } from "./../../../api";

export default function DraftList() {
  const navigate = useNavigate();

  const onClick = (item) => {
    navigate(`/course/${item._id}/menu`);
  }
  return (
    <InfinityList
      renderItem={(item) => <DraftItem onClick={() => onClick(item)} item={item} />}
      fetchData={(param) => getMyCourses({ ...param, mode: "draft" })}
    />
  );
}
