import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../../../constants/theme";
import { AlertCircle, ArrowLeft, Copy, Plus, Trash } from "react-feather";
import Button from "../../../components/Button";
import TextInput from "../../../components/Form/TextInput";

import courseForm, {
  Emitter as courseEmiter,
  validateLesson,
} from "./../../../forms/createNewCourse";
import { useEffect, useRef, useState } from "react";
import MainLesonBlock from "../../../components/BlockMaker/MainLesonBlock";
import { findError2, getUID } from "../../../utils";
import { Settings } from "../../../stores/Settings";
import FormErrorContainer from "../../../components/FormErrorContainer";
import PreView from "../../../components/PreView/PreView";

export default function AddLesson() {
  const styles = useTheme(dynamicStyle);
  const navigate = useNavigate();
  const mainBlock = useRef();
  const params = useParams();
  const [valid, setValid] = useState({ error: null });
  const formDisabled = false;
  const formField = params.type || "lessons";
  const lessonIndex = params.index || 0;
  const lesson = courseForm.link(`${formField}.${lessonIndex}`, {
    id: void 0,
    title: void 0,
    blocks: [],
    hometasks: [],
  });
  const _id = params.id;

  const [title, setTitle] = useState(lesson?.title || "Название урока");

  useEffect(() => {
    if (lesson.id === void 0) {
      courseForm.silentSet(`${formField}.${lessonIndex}`, {
        id: getUID(),
        title: void 0,
        blocks: [],
        hometasks: [],
      });
    } else {
      const v = validateLesson(`${formField}.${lessonIndex}`);
      setValid(v);
    }

    return courseEmiter.on(`*`, (val, key) => {
      switch (key) {
        case `${formField}.${lessonIndex}.title`:
          setTitle(val);
          break;
        default:
          break;
      }
      const v = validateLesson(`${formField}.${lessonIndex}`);
      setValid(v);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToHometask = () => {
    navigate(
      `/course/${params.id}/lessons/${params.type}/${params.index}/hometask/0`
    );
  };

  const wantRemoveLesson = () => {
    Settings.emit("show_dialog", {
      title: "Удаление урока",
      text: "Вы действительно хотите удалить этот урок, это действие необратимое.",
      acceptText: "Не удалять",
      cancelText: "Удалить",
      onCancel: removeLesson,
    });
  };

  const removeLesson = () => {
    const lessons = courseForm.get(formField);
    courseForm.set(
      formField,
      lessons.filter((l, i) => i !== +lessonIndex)
    );
    navigate(-1);
  };

  const goBack = () => {
    const lesson = courseForm.link(`${formField}.${lessonIndex}`);

    if (
      lesson?.title === undefined &&
      lesson?.blocks?.length === 0 &&
      lesson?.hometasks?.length === 0
    ) {
      removeLesson();
    } else {
      navigate(-1);
    }
  };

  const copyLesson = () => {
    const lessons = courseForm.get(formField);
    courseForm.set(
      formField,
      [...lessons, courseForm.get(`${formField}.${lessonIndex}`) ]
    );
    navigate(-1);

  }

  const goToPreview = () => {
    navigate(`/course/${_id}/lessons/${formField}/${lessonIndex}/preview`);
  }

  return (
    <>
      <div style={styles.title}>
        <ArrowLeft onClick={() => goBack()} style={styles.backIcon} size={32} />
        {title}
      </div>
      <div style={styles.body}>
        {findError2(valid, /title/) && (
          <FormErrorContainer>
            Урок обязательно должен содержать заголовок
          </FormErrorContainer>
        )}

        {["value", "_id"].includes(
          findError2(valid, /^blocks/)?.context?.key
        ) && (
            <FormErrorContainer>
              Значение блоков не должно быть пустым
            </FormErrorContainer>
          )}

        <div style={styles.label}>Название урока</div>
        <TextInput
          containerStyle={styles.textInputontainerStyle}
          name={`${formField}.${lessonIndex}.title`}
          collector={courseForm}
          disabled={formDisabled}
        />
        {params.type === 'lessons' && (
          <div style={styles.addHometask}>
            {courseForm.link(`${params.type}.${params.index}.hometasks.0`) !==
              undefined ? (
              <div style={styles.editHomeTask} onClick={goToHometask}>
                Редактировать домашнее задание
                {findError2(valid, /^hometasks/) && (
                  <AlertCircle size={16} style={styles.alertIcon} />
                )}
              </div>
            ) : (
              <Button onClick={goToHometask} text={"Добавить домашнее задание"} />
            )}
          </div>)}
        <div style={styles.label}>Контент</div>

        {findError2(valid, /^blocks/)?.type === "array.min" && (
          <FormErrorContainer>
            Урок обязательно должен содержать хотя бы один блок
          </FormErrorContainer>
        )}

        <MainLesonBlock
          ref={mainBlock}
          showAddButton={true}
          disabled={formDisabled}
          buttonText={"Добавить блок"}
          form={courseForm}
          emitter={courseEmiter}
          formField={`${formField}.${lessonIndex}.blocks`}
        />
      </div>
      <div style={styles.footer}>
        <div style={styles.footerWrapper}>
          <div onClick={wantRemoveLesson} style={styles.removeButton}>
            <Trash style={styles.addIcon} />
          </div>
        </div>
        <div style={styles.footerWrapper}>
          <div
            onClick={(e) => mainBlock.current.show(void 0, e)}
            style={styles.addButton}
          >
            <Plus style={styles.addIcon} />
          </div>
          <div style={{width:10}}></div>
          <div
            onClick={copyLesson}
            style={styles.addButton}
          >
            <Copy style={styles.addIcon} />
          </div>
        </div>
      </div>

      <PreView
        form={courseForm}
        emitter={courseEmiter}
        lessonIndex={lessonIndex}
        formField={formField}
        type={'lesson'}
      />
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    alertIcon: {
      marginLeft: 4,
      color: Colors.accent2,
      fontSize: 16,
    },
    editHomeTask: {
      ...Fonts.subhead2,
      color: Colors.color1,
      cursor: "pointer",
      display: 'flex',
      alignItems: 'center'
    },
    removeButton: {
      backgroundColor: Colors.accent2,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 52,
      width: 52,
      borderRadius: 25,
      cursor: "pointer",
    },
    addHometask: {
      marginBottom: 16,
    },
    body: {
      marginBottom: 100,
    },
    previewButton: {
      ...Fonts.headline1,
      backgroundColor: Colors.color1,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 48,
      borderRadius: 25,
      color: Colors.white,
      paddingLeft: 64,
      paddingRight: 64,
      marginRight: 16,
      cursor: "pointer",
    },
    addIcon: {
      color: Colors.white,
    },
    addButton: {
      backgroundColor: Colors.color1,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 52,
      width: 52,
      borderRadius: 25,
      cursor: "pointer",
    },
    footer: {
      position: "fixed",
      right: 40,
      bottom: 32,
    },
    label: {
      ...Fonts.subhead1,
      color: Colors.neutral2,
      marginBottom: 8,
    },
    textInputontainerStyle: {
      marginBottom: 16,
    },
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
    footerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 8,
    },
  };
};
