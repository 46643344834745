export const delimiter = ":";
const delimetrRXP = new RegExp(delimiter);

export default class Emitter {
  constructor() {
    this._listeners = {};
    this._map = {};
    this._dev = true;
  }

  static delimiter = delimiter;

  emit(event, message) {
    if (Array.isArray(event)) event = event.join(".");
    const res = [];

    if (this._listeners["*"] !== undefined) {
      let l = this._listeners["*"].length;

      while (l--) {
        res.push(this._listeners["*"][l].callback(message, event));
      }
    }

    if (this._listeners[event] !== undefined) {
      let l = this._listeners[event].length;

      while (l--) {
        res.push(this._listeners[event][l].callback(message, event));
      }
    }

    return res;
  }

  once(event, callback) {
    const _callback = (message) => {
      callback(message);
      this.off(event, _callback);
    };
    this.on(event, _callback);
  }

  addToPrefixMap(event) {
    const steps = event.split(".");

    let parent = this._map;

    for (let index = 0; index < steps.length; index++) {
      const element = steps[index];
      if (parent[element] === undefined) {
        parent[element] = {
          count: 1,
        };
      } else {
        parent[element].count += 1;
      }

      parent = parent[element];
    }
  }

  removeToPrefixMap(event) {
    const steps = event.split(".");

    let parent = this._map;

    for (let index = 0; index < steps.length; index++) {
      const element = steps[index];

      if (parent[element] !== undefined) {
        parent[element].count -= 1;

        if (parent[element].count === 0) {
          delete parent[element];
        } else {
          parent = parent[element];
        }
      }
    }
  }

  on(_event, callback) {
    if (Array.isArray(_event)) {
      const offArr = _event.map((e) => this.on(e, callback));

      return () => {
        return offArr.forEach((fn) => fn());
      };
    }

    if (!delimetrRXP.test(_event)) {
      _event = _event + `:${getUID()}`;
    }
    const [event, postfix] = _event.split(delimiter);

    this.addToPrefixMap(event);

    if (this._listeners[event] === undefined) this._listeners[event] = [];

    this._listeners[event].push({ _event, event, postfix, callback });

    return () => this.off(_event);
  }

  off(_event) {
    if (Array.isArray(_event)) return _event.forEach((e) => this.off(e));

    const [event, postfix] = _event.split(delimiter);

    if (event === "&") {
      // eslint-disable-next-line no-unused-vars
      for (let e in this._listeners) {
        this.off(`${e}${delimiter}${postfix}`);
      }

      return;
    }

    if (this._listeners[event] === undefined) this._listeners[event] = [];

    this._listeners[event].forEach((em, index) => {
      if (em._event === _event) {
        this.removeToPrefixMap(event);

        this._listeners[event].splice(index, 1);
      }
    });

    return this;
  }
}

const getUID = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};
