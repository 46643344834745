import { getClasses } from "../../../constants/theme";
import { makeImageUrl } from "../../../utils";

export default function ViewBlockPhoto({ block }) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes("container")}>
      <img
        src={makeImageUrl(block.value)}
        alt={""}
        className={classes("image")}
        crossorigin="anonymous"
      />
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    image: {
      width: "100%",
      borderRadius: 10,
      overflow: "hidden",
      //height: 193,
    },
    container: {
      marginTop: 0,
      marginBottom: 12,
      borderRadius: 10,
      overflow: "hidden",
    },
  };
};
