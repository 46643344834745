import React, { useEffect, useReducer } from "react";
import { errorHandler } from "../api";
import { useTheme } from "../constants/theme";

const initialState = {
  data: [],
  total: 0,
  loading: false,
  qp: void 0,
  first_fetch: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "inited":
      return {
        ...state,
        data: action.result.data,
        total: action.result.total,
        first_fetch: true,
        qp:
          action.result.data.length > 0
            ? action.result.data[action.result.data.length - 1]._qp
            : void 0,
      };
    case "addNext":
      return {
        ...state,
        data: [...state.data, ...action.result.data],
        total: action.result.total,
        first_fetch: true,
        qp:
          action.result.data.length > 0
            ? action.result.data[action.result.data.length - 1]._qp
            : void 0,
      };
    default:
      return { ...state };
  }
}

export default function InfinityList(props) {
  const styles = useTheme(dynamicStyle);
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = async (options = {}) => {
    try {
      const res = await props.fetchData({
        size: props.size || 10,
        ...options,
      });

      return res;
    } catch (e) {
      errorHandler(e);
      return {
        data: [],
        total: 0,
      };
    }
  };

  const init = async () => {
    const res = await fetchData({
      query_point: void 0,
    });

    dispatch({ type: "inited", result: res });
  };

  // const next = async () => {
  //   const res = await fetchData({
  //     query_point: state.qp,
  //   });

  //   dispatch({ type: "addNext", result: res });
  // };

  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        {state.data.map((item, index, arr) => (
          <React.Fragment key={index + ""}>
            {props.renderItem(item, index, arr)}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}



const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {

    }
  }
}