import moment from "moment";
import { getClasses } from "../../../constants/theme";

export default function DraftItem({ item, onClick }) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes("container")} onClick={onClick}>
      <div className={classes("subtitle")}>
        Изменен {moment(item.updated_at).format("DD.MM.YYYY HH:mm")}
      </div>
      <div className={classes("title")}>{item.title}</div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    'container': {
      marginTop: 8,
      backgroundColor: Colors.neutral4,
      borderRadius: 10,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 12,
      paddingBottom: 12,
      cursor: "pointer",
    },
    'container:hover': {
      opacity:0.5

    },
    title: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    subtitle: {
      ...Fonts.footnote,
      color: Colors.neutral2,
    },
  };
};
