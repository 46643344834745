import React, { useState, useEffect, useRef } from "react";
import { getClasses} from "../../constants/theme";
import { makeImageUrl } from "../../utils";
import { Loader } from "../../stores/Loader";
import {  Check,  Music } from "react-feather";
import { Settings } from "../../stores/Settings";
import AudioPlayer from "../AudioPlayer";

export const AudioLoader = function (props) {
  const classes = getClasses(dynamicStyle);
  const [audio, setAudio] = useState(props.audio);
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(props?.audio?._id);
  const fFile = useRef();


  useEffect(() => {
    if (audio instanceof File){
      onFileChange({target:{files:[audio]}})
      setAudio({});
    }
  }, []);

  useEffect(() => {
    // props.autoOpen && audio.tmpid === undefined && openVideoPicker();
  }, []);

  useEffect(() => {
    if (!audio?.tmpid) return;
    const off = Loader.on(
      `tasks.${audio.tmpid}.progress:${props.blockId}`,
      ({ next }) => {
        setProgress(next);
      }
    );

    return off;
  }, [audio?.tmpid, props.blockId]);

  useEffect(() => {
    if (!audio?.tmpid) return;

    return Loader.get(
      `tasks.${audio?.tmpid}.ready:${props.blockId}`,
      audio,
      ({ next, prev, def }) => {
        if (next === true) {
          const timg = Loader.get(`tasks.${audio?.tmpid}`);
          setLoaded(true);
          setAudio(timg);
          props.onComplete(timg);
          Loader.remove(`tasks.${audio?.tmpid}`);
        }
      }
    );
  }, [audio, audio?.tmpid, props.blockId, props]);

  const onOpenFileBrowser = () => {
    fFile.current.click();
  };
  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const MaxFileSize = Settings.get("me.subscription.max_file_size");

    if (file.size > Settings.get("me.subscription.max_file_size")) {
      Settings.emit("local_notification", {
        level: "error",
        text: "Превышен максимальный размер файла",
        subtext: `Максимальный размер файла ${(
          MaxFileSize /
          1024 /
          1024
        ).toFixed(2)}Мб`,
      });
      return props.onItemRemoved(audio, props.blockId);
    }

    setLoaded(false);
    setProgress(0);

    const tmpfile = Loader.load(file, {
      blockId: props.blockId,
      options: {
        size: file.size,
        filename: file.name,
      },
      prefix: props.prefix,
    });

    props.onComplete(tmpfile);

    setAudio({
      local_url: URL.createObjectURL(file),
      url: file.uri,
      tmpid: tmpfile.tmpid,
    });
  };

  return (
    <div>
      {Object.keys(audio).length > 0 ? (
        <div className={classes('imageContainer')}>
          <div className={classes('image')}>
            <div style={{ flex: 1 }}></div>
            {loaded && (
              <AudioPlayer
                src={
                  audio.local_url
                    ? audio.local_url
                    : makeImageUrl(audio, void 0, "filename")
                }
              />
            )}
          </div>

          {!loaded && props.disabled !== true ? (
            <div className={classes('progressWrapper')}>
              <div className={classes('progressText')}>{progress} %</div>
            </div>
          ) : (
            <div className={classes('musicWrapper')}>
              <Music className={classes('musicIcon')} name={"music"}/>
            </div>
          )}

          {/* 
          {loaded && (
            <TouchableOpacity onClick={play} style={styles.playWrapper}>
              <Icon style={styles.playIcon} name={'play'} />
            </TouchableOpacity>
          )} */}

          {loaded && (
            <div className={classes('readyWrapper')}>
              <Check className={classes('readyIcon')} name={"check"} />
            </div>
          )}
        </div>
      ) : (
        <div disabled={props.disabled} onClick={onOpenFileBrowser}>
          {props.Component}
        </div>
      )}
      <input
        ref={fFile}
        accept=".mp3,audio/*"
        className={classes('fileInput')}
        type="file"
        onChange={onFileChange}
      />
    </div>
  );
};

AudioLoader.defaultProps = {
  audio: void 0,
};

const dynamicStyle = (Colors, Fonts) => {
  return {
    fileInput: {
      display: "none",
    },
    progressContainer: {
      backgroundColor: Colors.neutral2,
    },
    playWrapper: {
      display: "flex",
      position: "absolute",
      backgroundColor: Colors.modal,
      width: 48,
      height: 48,
      borderRadius: 32,
      justifyContent: "center",
      alignItems: "center",
    },
    playIcon: {
      color: Colors.absoluteWhite,
      fontSize: 28,
      marginLeft: 4,
    },
    musicIcon: {
      fontSize: 28,
      color: Colors.neutral2,
    },
    musicWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
    },
    progressWrapper: {
      width: 48,
      height: 48,
      borderRadius: 32,
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      display: "flex",

      backgroundColor: Colors.modal,
    },
    fullscreenIcon: {
      color: Colors.absoluteWhite,
      fontSize: 20,
    },
    progressText: {
      marginTop: 2,
      ...Fonts.footnote,
      color: Colors.absoluteWhite,
    },
    readyIcon: {
      color: Colors.white,
      fontSize: 14,
    },
    readyWrapper: {
      backgroundColor: Colors.modal,
      width: 20,
      height: 20,
      alignItems: "center",
      justifyContent: "center",
      borderBottomRightRadius: 4,
      position: "absolute",
      display: "flex",
      left: 0,
      top: 0,
    },
    imageContainer: {
        position: "relative",
        alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      overflow: "hidden",
      display: "flex",
    },
    refreshWrapper: {
      backgroundColor: Colors.modal,
      width: 50,
      height: 50,
      alignItems: "center",
      justifyContent: "center",
      borderTopRightRadius: 16,
      position: "absolute",
      display: "flex",
      left: 0,
      bottom: 0,
    },
    refreshIcon: {
      color: Colors.absoluteWhite,
      fontSize: 22,
    },
    image: {
      width: "100%",
      minHeight: 124,
      flex: 1,
      borderRadius: 10,
      justifyContent: "flex-start",
      alignItems: "flex-end",
      backgroundColor: Colors.neutral3,
      display: "flex",
    },
  };
};
