import { useEffect, useRef, useState } from "react";
import { getTheme } from "../constants/theme";
import { Play, Pause } from "react-feather";
import { mmss } from "../utils";

export default function AudioPlayer(props) {
  const styles = getTheme(dynamicStyle);
  const player = useRef();
  const trackbar = useRef();
  const [playing, setPlaying] = useState(false);
  const [metadata, setMetadata] = useState({ duration: 0 });

  const play = () => {
    player.current.play();
  };

  const pause = () => {
    player.current.pause();
  };

  useEffect(() => {
    player.current.onplay = () => {
      setPlaying(true);
    };
    player.current.onpause = () => {
      setPlaying(false);
    };
    player.current.onloadedmetadata = (event) => {
      setMetadata({ duration: player?.current?.duration * 1000 });
    };

    player.current.ontimeupdate = (event) => {
      const proc = (event.target.currentTime / event.target.duration) * 100;
      trackbar.current.value = proc;
    };
  }, []);

  const seek = (event) => {
    const time = (metadata.duration / 1000) * (+event.target.value / 100);
    player.current.currentTime = time;
  };

  return (
    <>
      <div style={styles.container}>
        <div onClick={playing ? pause : play} style={styles.iconWrapper}>
          {playing ? (
            <Pause style={styles.icon} />
          ) : (
            <Play style={styles.icon} />
          )}
        </div>
        <input
          onChange={seek}
          ref={trackbar}
          style={styles.range}
          type="range"
          id="seek-slider"
          max="100"
          defaultValue={0}
        />
        <div style={styles.time}>{mmss(metadata.duration)}</div>
      </div>
      <audio
        ref={player}
        preload="metadata"
        controls={false}
        src={props.src}
        crossorigin="anonymous"
      ></audio>
    </>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    time: {
      ...Fonts.subhead2,
      color: Colors.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      color: Colors.white,
    },
    iconWrapper: {
      display: "flex",
      heigt: 24,
      width: 24,
    },
    range: {
      display: "flex",
      width: "100%",
      marginRight: 8,
      marginLeft: 8,
      background: Colors.white 
    },
    container: {
      marginLeft: 16,
      marginRight: 16,
      marginBottom: 12,
      display: "flex",
      width: "100%",
    },
  };
};
