import { errorHandler, getSubscription } from "../api";
import { Settings } from "../stores/Settings";

export default async function changeAccount(account, mode) {
    try {
        console.log(account, mode)
        const { subscription } = await getSubscription(account._id === Settings.get('me._id') ? void 0 : account._id);

        Settings.set('account.client', account);
        Settings.set('account.mode', mode);
        Settings.set('account.subscription', subscription);

        return Settings.get('account');

    } catch (err) {
        errorHandler(err);
        return false;
    }
}
