import { useTheme } from "../../constants/theme";
import Appbar from "../../components/Appbar";
import { useNavigate } from "react-router-dom";
import DraftList from "./SubPages/DraftList";

export default function ArticlesListPage() {
  const styles = useTheme(dynamicStyle);
  const navigate = useNavigate();

  const goToCreateCourse = () => {
    navigate("/articles/new");
  };


  return (
    <>
      <Appbar rightSection={<div style={styles.addButtonStyle} onClick={goToCreateCourse}>
        Добавить статью
      </div>} />

      <div style={styles.listContiner}>
        <div style={styles.listWrapper}>
          <div style={styles.title}>Статьи</div>
            <DraftList />
        </div>
      </div>
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    title: {
      ...Fonts.title1,
      color: Colors.neutral1,
      marginBottom: 24,
    },
    listWrapper: {
      width: 500,
    },
    listContiner: {
      display: "flex",
      flex: 1,
      flexDirection: "conumn",
      width: "100%",
      justifyContent: "center",
    },
    addButtonStyle: {
      ...Fonts.subhead2,
      backgroundColor: Colors.color1,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 16,
      color: Colors.white,
      cursor: "pointer",
    },
    exitButtonStyle: {
      ...Fonts.subhead2,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 16,
      color: Colors.neutral1,
      cursor: "pointer",
      marginLeft: 16,
    },
    submenu: {
      justifyContent: "center",
      display: "flex",
    },
    appItemFlex1: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
    },
    appbar: {
      marginLeft: 8,
      marginRight: 8,

      height: 78,
      display: "flex",
      alignItems: "center",
    },
  };
};
