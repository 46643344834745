import { Settings } from "../stores/Settings";
import { articleSerialize, courseSerialize, noop } from "../utils";
import form, { rehydrate as rehydrateForm } from "../forms/createNewArticle";
import { createArticle, createCourse, errorHandler, updateArticle, updateCourse } from "../api";
import moment from "moment";

export default async function saveArticle({ before = noop, silent = false }) {
  const needWite = rehydrateForm();

  if (needWite.length > 0 && silent === false) {
    Settings.emit("local_notification", {
      text: "Необходимо дождаться дозагрузки файлов",
      level: "warn",
    });

    return false;
  }

  await before();

  try {
    !silent && Settings.emit("loading", "Загрузка");

    //form.validate();
    const article = form.get();

    const sarticle = articleSerialize(article);
    if (article._id === undefined) {
      const res = await createArticle(sarticle);

      form.set("_id", res._id);

      !silent && Settings.emit("local_notification", "Статья сохранен");
    } else {
      await updateArticle(sarticle);
      !silent && Settings.emit("local_notification", "Статья обновлен");
    }
    return true;
  } catch (e) {
    e.i18n = {
      title: "Ошибка валидации",
      message: e.message,
    };
    !silent && errorHandler(e);
    return false;
  } finally {
    !silent && Settings.emit("loading", false);
  }
}
