import { useRef, useState } from "react";
import { validate } from "./../../api";

export const useFormCollectorWithState = function (data, schema, updater) {
  const ref = useRef(void 0);
  const [state, setState] = useState(data);

  if (ref.current === void 0) {
    ref.current = getFormCollector(data, schema, (a, b, c, d) => {
      setState({ ...d });
      updater && updater(a, b, c, d);
    });
  }

  return [ref.current, state];
};

export const useFormCollector = function (data, schema, updater) {
  const ref = useRef(void 0);

  if (ref.current === void 0) {
    ref.current = getFormCollector(data, schema, updater);
  }

  return ref.current;
};

export const getFormCollector = function (
  initialData = {},
  validationSchema,
  updater
) {
  let initial = deepCopy(initialData); //JSON.parse(JSON.stringify(_)); // TODO worst copy
  let schema = validationSchema;
  const resetFunc = {};
  const errorFunc = {};
  const data = initialData;

  return {
    updateSchema(s) {
      schema = s;
    },
    updateInitialData(id) {
      initial = deepCopy(id);
    },
    validate(key, s = schema, options = {}) {
      const d = key ? { [key]: data[key] } : data;

      // eslint-disable-next-line no-unused-vars
      for (let _ in errorFunc) {
        errorFunc[_](void 0);
      }

      if (s) {
        const res = validate(s, d, options);

        (res.details || []).forEach(({ path }) => {
          path = path.join(".");

          errorFunc[path] &&
            errorFunc[path]({
              isError: true,
              error: res,
            });
        });

        if (res instanceof Error) throw res;
      }
    },
    validate2(key, s = schema, options = {}) {
      const d =
        typeof key === "string" || !key
          ? key
            ? { [key]: data[key] }
            : data
          : key;

      // eslint-disable-next-line no-unused-vars
      for (let _ in errorFunc) {
        errorFunc[_](void 0);
      }

      if (s) {
        const res = validate(s, d, options);

        (res.details || []).forEach(({ path }) => {
          path = path.join(".");

          errorFunc[path] &&
            errorFunc[path]({
              isError: true,
              error: res,
            });
        });

        if (res instanceof Error) return { error: res };
      }

      return { error: null };
    },
    getAll: () => {
      return data;
    },
    transaction(cb) {
      cb && cb.call(this, this);
      updater && updater(void 0, void 0, true, data);
    },
    get: (key, def) => {
      const d = key ? get(data, key, def) : data;
      return d !== undefined ? deepCopy(d) : d; // TODO worst copy, because maybe mutate data
    },
    link: (key, def) => {
      const d = key ? get(data, key, def) : data;
      return d; // TODO worst copy, because maybe mutate data
    },
    silentSet: (key, value, h, r) => {
      if (typeof r === "function") {
        value = r(value);
      }
      set(data, key, value);
      h && h(value);
    },
    set: (key, value, h, r) => {
      if (typeof r === "function") {
        value = r(value);
      }

      set(data, key, value);
      // data[key] = value;
      h && h(value);
      updater && updater(key, value, false, data);
    },
    setAndReset: function (key, value, h, r) {
      this.set(key, value, h, r);
      this.update(key, value, h);
    },
    update(key, value, h) {
      resetFunc[key] && resetFunc[key](value);
    },
    reset: (obj = initial) => {
      // eslint-disable-next-line no-unused-vars
      for (let k in obj) {
        data[k] = obj[k];
        resetFunc[k] && resetFunc[k](obj[k]);
        updater && updater(k, obj[k], true, data);
      }
    },
    resetOnce: (obj = initial) => {
      // eslint-disable-next-line no-unused-vars
      for (let k in obj) {
        data[k] = obj[k];
        resetFunc[k] && resetFunc[k](obj[k]);
      }
      updater && updater(void 0, void 0, true, data);
    },
    resetCallback: (key, fn) => {
      resetFunc[key] = fn;
    },
    errorCallback: (key, fn) => {
      errorFunc[key] = fn;
    },
    removeErrorCallback: (key) => {
      errorFunc[key] = void 0;
    },
  };
};

export const deepCopy = function deepCopy(oldObj) {
  var newObj = oldObj;

  if (oldObj && typeof oldObj === "object") {
    if (oldObj instanceof Date) {
      return oldObj; // TODO - may be need copy
    }

    newObj = Array.isArray(oldObj) ? [] : {};

    for (var i in oldObj) {
      newObj[i] = deepCopy(oldObj[i]);
    }
  }

  return newObj;
};

export const get = (obj, path, defaultValue) => {
  if (typeof path === "string") path = path.split(".");

  let node = obj;

  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    if (!node) {
      node = undefined;

      break;
    }

    node = node[key];
  }

  return typeof node === "undefined" ? defaultValue : node;
};

export const set = (obj, path, defaultValue, setter) => {
  if (typeof path === "string") path = path.split(".");

  if (typeof setter === "undefined") setter = defaultValue;

  let value = get(obj, path);

  if (
    (typeof value === "undefined" ||
      (typeof value === "number" && isNaN(value))) &&
    typeof defaultValue !== "function"
  ) {
    value = defaultValue;
  }

  let node = obj;

  for (var i = 0; i < path.length - 1; i++) {
    const key = path[i];
    const nextKey = +path[i + 1];

    if (!node[key]) node[key] = isNaN(nextKey) ? {} : [];

    node = node[key];
  }

  node[path[i]] = typeof setter === "function" ? setter(value) : setter;

  return obj;
};
