import { getClasses } from "../../../constants/theme";

import background from './../../../assets/iphone12.png';
import Article from "./Article";
import Hometask from "./Hometask";
import Lesson from "./Lesson";

/*
        form={courseForm}
        emitter={courseEmiter}
        formField={`${formField}.${lessonIndex}.blocks`}
*/

const getScale = () => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    const mh = 731;
    const mw = 336;
    const ow = (w - 500) / 2 - 100;
    const oh = h - 150;

    let sw = ow / mw;
    let sh = oh / mh;
    let s = sh < sw ? sh : sw;

    return { scale: s > 1 ? 1 : s };
}

export default (props) => {
    const classes = getClasses(dynamicStyle);

    const getComponent = () => {
        switch (props.type) {
            case 'lesson':
                return <Lesson {...props} />
            case 'hometask':
                return <Hometask {...props} />
            case 'article':
                return <Article {...props} />


            default:
                return null;
        }
    }

    const { scale } = getScale();


    return <div style={{ scale: `${scale}`, top: 50, left: 50 }} id="ddd" className={classes('container')}>
        <div className={classes('wrapper')}>
            {getComponent()}
        </div>
    </div>
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
    return {
        wrapper: {
            height: 731,
            width: 337,
            //  backgroundColor: '#0051ff8a',
            margin: 0,
            borderRadius: 31,
            marginTop: 18,
            marginLeft: 21,
            marginRight: 21,
            marginBottom: 18,
            backgroundRepeat: 'no-repeat',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        container: {
            display: 'flex',
            backgroundSize: 'contain',
            backgroundImage: `url(${background})`,
            position: 'fixed',
            // top: 75,
            // left: 50,
        }
    }
}