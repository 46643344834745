import { useEffect, useState } from "react";
import { getClasses } from "../../../constants/theme";
import { makeImageUrl } from "../../../utils";

export default function ViewBlockBuiltInPDF({ block }) {
  const classes = getClasses(dynamicStyle);
  const [blob, setBlob] = useState();


  useEffect(() => {
    fetch(makeImageUrl(block.value)).then(res => res.blob()).then(res => setBlob(res));
  }, [block.value])

  return (
    <div className={classes("container")}>
      {blob && <embed
        src={URL.createObjectURL(blob)}
        type="application/pdf"
        width="500px"
        height="500px"
        crossorigin="anonymous"
      />}

    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    image: {
      width: "100%",
      borderRadius: 10,
      overflow: "hidden",
      //height: 193,
    },
    container: {
      marginTop: 0,
      marginBottom: 12,
      borderRadius: 10,
      overflow: "hidden",
    },
  };
};
