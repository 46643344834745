import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../../../constants/theme";
import { ArrowLeft } from "react-feather";
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import form, {
  Emitter as courseEmiter, validLessons,
} from "./../../../forms/createNewCourse";
import LessaonItem from "../components/LessonItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { findError } from "../../../utils";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  paddingTop: 0.1,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({});

export default function Lessons() {
  const styles = useTheme(dynamicStyle);
  const navigate = useNavigate();
  const params = useParams();
  const _id = params.id;
  const [lessons, setLessons] = useState(form.get("lessons"));
  const [finalLesson, setFinalLesson] = useState(form.get("final_lesson.0"));
  const [introductoryLesson, setIntroductoryLesson] = useState(
    form.get("introductory_lesson.0")
  );

  useEffect(() => {
    const lessonOff = courseEmiter.on("lessons", (l) => {
      setLessons([...l]);
    });
    const finalLessonOff = courseEmiter.on("final_lesson", (l = []) => {
      setFinalLesson(l[0]);
    });
    const introductoryLessonOff = courseEmiter.on(
      "introductory_lesson",
      (l = []) => {
        setIntroductoryLesson(l[0]);
      }
    );

    return () => {
      lessonOff();
      finalLessonOff();
      introductoryLessonOff();
    };
  }, []);

  const goToLesson = (type, index) => {
    navigate(`/course/${_id}/lessons/${type}/${index}`);
  };

  const goToNewLesson = () => {
    const index = form.link("lessons.length");

    navigate(`/course/${_id}/lessons/lessons/${index}`);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const _lessons = form.get("lessons");

    const items = reorder(
      _lessons,
      result.source.index,
      result.destination.index
    );

    form.set("lessons", [...items]);
  };

  const valid = validLessons();

  return (
    <>
      <div style={styles.title}>
        <ArrowLeft
          onClick={() => navigate(-1)}
          style={styles.backIcon}
          size={32}
        />
        Уроки
      </div>
      <div>
        <div style={styles.label}>Обязательные уроки</div>

        <LessaonItem
          title={introductoryLesson?.title || "Создать"}
          subtitle={`Вступительный урок`}
          onClick={() => goToLesson("introductory_lesson", 0)}
          hasError={findError(valid, 'introductory_lesson', 0)}
        />

        <LessaonItem
          title={finalLesson?.title || "Создать"}
          subtitle={`Завершающий урок`}
          onClick={() => goToLesson("final_lesson", 0)}
          hasError={findError(valid, 'final_lesson', 0)}
        />

        <div style={{ marginBottom: 16 }}></div>

        <div style={styles.label}>Уроки</div>
        <div style={{ marginBottom: 16 }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="1">
              {(dpprovided, snapshot) => (
                <div
                  {...dpprovided.droppableProps}
                  ref={dpprovided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {lessons.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <LessaonItem
                              title={item.title || "Нет названия"}
                              subtitle={`Урок ${index + 1}`}
                              onClick={() => goToLesson("lessons", index)}
                              item={item}
                              key={item.id}
                              hasError={findError(valid, `lessons.${index}`)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {dpprovided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Button text={"Добавить урок"} onClick={() => goToNewLesson()} />
        <div style={{ marginBottom: 16 }}></div>
      </div>
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    label: {
      ...Fonts.subhead1,
      color: Colors.neutral2,
      marginBottom: 8,
    },
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
  };
};
