import { useEffect, useState } from "react";
import { Trash } from "react-feather";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCreateCourseOptions,
  getMyCourse,
  createCourse,
  errorHandler,
  removeCourse,
} from "../../api";
import Logo from "../../components/Logo";
import { useTheme } from "../../constants/theme";
import { Settings } from "../../stores/Settings";
import { enrichCourse } from "../../utils";
import form from "./../../forms/createNewCourse";
import CourseSavedComponent from "./components/CourseSavedComponent";
import Appbar from "../../components/Appbar";
import PreView from "../../components/PreView/PreView";

export default function CreateNewCourse(props) {
  const styles = useTheme(dynamicStyle);
  const params = useParams();
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Promise.all([
      getCreateCourseOptions(),
      params.id !== "new" ? getMyCourse(params.id) : createCourse({}),
    ]).then(([options, course]) => {
      form.reset({
        ...enrichCourse(course),
        _options: options,
      });

      if (params.id === "new") {
        params.id = course._id;
        window.history.replaceState(
          null,
          window.document.title,
          `/course/${course._id}/menu`
        );
      }

      setReady(true);
    });

    return () => form.reset();
  }, []);

  const wantRemoveDraft = async () => {
    Settings.emit("show_dialog", {
      title: "Удаление шаблона",
      text: "Вы действительно хотите удалить этот шаблон, это действие необратимое.",
      acceptText: "Не удалять",
      cancelText: "Удалить",
      onCancel: removeDraft,
    });
  };

  const removeDraft = async () => {
    try {
      await removeCourse({ _id: form.get("_id") });
      navigate(-1);
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <>
      <Appbar />

      <div style={styles.listContiner}>
        <div style={styles.listWrapper}>{ready && <Outlet />}</div>
      </div>

      {/menu$/.test(location.pathname) && (
        <div style={styles.footer}>
          <div style={styles.footerWrapper}>
            <div onClick={wantRemoveDraft} style={styles.removeButton}>
              <Trash style={styles.addIcon} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    menuitem: {
      ...Fonts.headline1,
      color: Colors.color1,
      marginTop: 8,
      cursor: "pointer",
    },
    backIcon: {
      color: "#FF15F6",
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
    listWrapper: {
      width: 500,
    },
    listContiner: {
      display: "flex",
      flex: 1,
      flexDirection: "conumn",
      width: "100%",
      justifyContent: "center",
    },
    appItemFlex1: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
    },
    appbar: {
      backgroundColor: Colors.backgroundLayout,
      top: 0,
      marginLeft: 24,
      marginRight: 24,
      position: "fixed",
      left: 0,
      right: 0,
      height: 78,
      display: "flex",
      alignItems: "center",
      zIndex: 1,
    },
    submenu: {
      display: "flex",
      alignItems: "center",
    },
    exitButtonStyle: {
      ...Fonts.subhead2,
      paddingLeft: 28,
      paddingRight: 28,
      color: Colors.neutral1,
      cursor: "pointer",
      marginLeft: 16,
    },

    footerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 8,
    },
    addIcon: {
      color: Colors.white,
    },
    addButton: {
      backgroundColor: Colors.color1,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 52,
      width: 52,
      borderRadius: 25,
      cursor: "pointer",
    },
    footer: {
      position: "fixed",
      right: 40,
      bottom: 32,
    },
    alertIcon: {
      marginLeft: 4,
      color: Colors.accent2,
      fontSize: 16,
    },
    editHomeTask: {
      ...Fonts.subhead2,
      color: Colors.color1,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    removeButton: {
      backgroundColor: Colors.accent2,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 52,
      width: 52,
      borderRadius: 25,
      cursor: "pointer",
    },
    addHometask: {
      marginBottom: 16,
    },
  };
};
