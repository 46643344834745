import { getClasses } from "../../../constants/theme";
import { clearnbsp } from "../../../utils";

export default function ViewBlockHTML({ block, first }) {
  const classes = getClasses(dynamicStyle);
  
  return (
    <div className={classes("constainer")}>
      <div dangerouslySetInnerHTML={{__html: clearnbsp(block?.value)}} className={classes("label")}></div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    constainer: {
      marginTop: 0,
      marginBottom: 8,
    },
    label: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
      whiteSpace:'pre-wrap'
    },
  };
};
