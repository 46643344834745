import React, { useRef, useState } from "react";
import { Plus, RefreshCcw, X } from "react-feather";
import { getClasses } from "../../../constants/theme";
import { DefaultEditor } from "react-simple-wysiwyg";
import { clearnbsp } from "../../../utils";

export default function renderBlockHTMLEditor(
  item,
  scrollRef,
  actions,
  options = {}
) {
  const classes = getClasses(dynamicClasses);

  return (
    <div className={classes("constainer")}>
      <div className={classes("wrapper")}>
        <div className={classes("topSection")}>
        {options?.disableActions?.changeBlockType !== true && <div onClick={(e) => actions.changeBlockType(item, e)}>
            <RefreshCcw name="plus" size={18} className={classes("iconrefresh")} />
          </div>}
          {!options.hideLabel && (
            <div className={classes("label")}>Текстовый редактор</div>
          )}
          {options.disabled !== true && options.disableActions !== true && (
            <div className={classes("actions")}>
              {options?.disableActions?.showDialog !== true && (
                <div onClick={(e) => actions.showDialog(item, e)}>
                  <Plus className={classes("iconRight")} />
                </div>
              )}
              {options?.disableActions?.removeBlock !== true && (
                <div onClick={() => actions.removeBlock(item)}>
                  <X className={classes("iconRight")} />
                </div>
              )}
            </div>
          )}
        </div>

        <DefaultEditor
          value={item.value}
          onChange={({ target }) => {
            actions.saveValue(item.id, clearnbsp(target.value));
          }}
        />

      </div>
    </div>
  );
}

const dynamicClasses = (Colors, Fonts) => {
  return {
    loadButtonText: {
      ...Fonts.subhead2,
      color: Colors.white,
    },
    loadButton: {
      height: 48,
      backgroundColor: Colors.color1,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 16,
      display: "flex",
      flexDirection: "column",
    },
    icon: {
      color: Colors.neutral2,
      fontSize: 18,
    },
    actions: {
      display: "flex",
      flexDirection: "row",
    },
    inputContainerStyle: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    constainer: {
      marginTop: 8,
      backgroundColor: Colors.neutral4,
      borderRadius: 10,
    },
    wrapper: {
      marginLeft: 12,
      marginRight: 12,
      paddingTop: 8,
      paddingBottom: 8,
    },

    input: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
    },

    label: {
      ...Fonts.subhead2,
      color: Colors.neutral2,
      flex: 1,
      marginLeft: 8,
      marginRight: 8,
      display: "flex",
      flexDirection: "column",
    },
    topSection: {
      display: "flex",

      flexDirection: "row",
      alignItems: "center",
      marginBottom: 8,
    },
    iconLeft: {
      color: Colors.neutral2,
      fontSize: 22,
      marginRight: 2,
    },
    iconRight: {
      color: Colors.neutral2,
      fontSize: 22,
      marginLeft: 2,
    },
    iconrefresh: {
      color: Colors.neutral2,
      fontSize: 16,
      marginLeft: 0,
    },
  };
};
