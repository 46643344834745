import React, { useEffect, useRef, useState } from "react";
import { QRCode } from "react-qr-svg";
import { Link, useNavigate } from "react-router-dom";
import { sendQRUUIDCode } from "../../api";
import Logo from "../../components/Logo";
import { getClasses, useTheme } from "../../constants/theme";
import { Settings } from "../../stores/Settings";
import { UUIDGeneratorBrowser, wait } from "../../utils";

import step1Img from './../../assets/a1.png';
import step2Img from './../../assets/a2.png';
import step3Img from './../../assets/a3.png';

import appstore from './../../assets/appstore.png';
import playmarket from './../../assets/playmarket.png';


export default function AuthPage() {
  const styles = useTheme(dynamicStyle);
  const classes = getClasses(dynamicStyle);

  const [qr, setQr] = useState(Settings.get("webuuid"));
  const navigate = useNavigate();
  const cancel = useRef(false);

  useEffect(() => {
    if (Settings.has("authorizationToken")) {
      navigate("/");
      return;
    }
    let uuid = Settings.get("webuuid");

    if (!uuid) {
      uuid = UUIDGeneratorBrowser("mr:qr:");
      Settings.set("webuuid", uuid);
    }

    setQr(uuid);

    waitAutorization(uuid);

    return () => {
      cancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const waitAutorization = async (uuid) => {
    if (cancel.current) return;
    try {
      const res = await sendQRUUIDCode(uuid);

      if (res.token === undefined) {
        await wait(5000);
        waitAutorization(uuid);
      } else {
        Settings.set("authorizationToken", res.token);
        if (res?.params?.mode && res?.params?.mode === "draft") {
          navigate(`/course/${res?.params?.crs_id}/menu`);
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      // alert(1)
    }
  };

  return (
    <div className={classes('container')}>

      <div className={classes('title')}>
        Отсканируйте код при помощи приложения Udeu
      </div>
      <div className={classes('body')}>
        <div className={classes('instruction')}>
          <div className={classes('step')}>
            <div className={classes('stepimgwrapper')}>
              <img src={step1Img} />
            </div>
            <div className={classes('stepText')}>
              Откройте приложение Udeu или загрузите его
              <div className={classes('stores')}>
                <a target={'_blank'} href={'https://apps.apple.com/ru/app/udeu/id1574167472'}>
                  <img src={appstore} />
                </a>
                <a target={'_blank'} href={'https://play.google.com/store/apps/details?id=com.getback.marafonapp'}>

                  <img src={playmarket} />
                </a>
              </div>
            </div>
          </div>

          <div className={classes('step')}>
            <div className={classes('stepimgwrapper')}>
              <img src={step2Img} />
            </div>
            <div className={classes('stepText')}>Откройте профиль, переключитесь в режим организатора</div>
          </div>



          <div className={classes('step')}>
            <div className={classes('stepimgwrapper')}>
              <img src={step3Img} />
            </div>
            <div className={classes('stepText')}>Откройте меню Web-редактор в профиле и наведите телефон на QR-код</div>
          </div>




        </div>
        <div style={{ width: 64 }}></div>
        <div>
          <div className={classes('qrWrapper')} >
            <QRCode
              bgColor={styles.qrBgColor}
              fgColor={styles.fgColor}
              level="Q"
              style={styles.qr}
              value={qr}
            />
          </div>
        </div>
      </div>

      <div className={classes('logoWrapper')}>
        <Logo />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    stores: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 8
    },
    stepimgwrapper: {
      marginRight: 24
    },
    instruction: {
      width: 400
    },
    stepText: {
      color: Colors.neutral1,
      ...Fonts.headline1
    },
    step: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    qr: {
      width: 300,
    },
    qrBgColor: Colors.backgroundLayout,
    fgColor: Colors.neutral1,
    logoWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "flex-end",
      position: "absolute",
      bottom: 24,
      right: 16,
    },
    li: {
      ...Fonts.headline1,
      color: Colors.neutral1,
      lineHeight: "32px",
    },
    title: {
      ...Fonts.title2,
      color: Colors.neutral1,
      margin: 48,
    },
    qrWrapper: {
      height: 428,
      width: 428,
      borderColor: Colors.neutral3,
      borderWidth: 2,
      borderStyle: 'solid',
      borderRadius: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: "center"
    },
    container: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
    },
  };
};
