import { getClasses } from "../../../constants/theme";
import { makeImageUrl } from "../../../utils";

export default function ViewBlockVideo({ block }) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes("container")}>
      <video
        preload={"metadata"}
        controls={"controls"}
        className={classes("image")}
        anonymous={true}
        crossorigin="anonymous"
      >
        <source anonymous={true}
        crossorigin="anonymous"
          src={makeImageUrl(block.value, void 0, "filename")} />
      </video>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    image: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      borderRadius: 8,
    },
    container: {
      marginTop: 0,
      marginBottom: 12,
      overflow: "hidden",
      height: 193,
      backgroundColor: Colors.neutral3,
      borderRadius: 8,
    },
  };
};
