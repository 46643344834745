import React, { useState, useRef, useEffect } from "react";
import { useTheme } from "../../constants/theme";
import { DefaultEditor } from "react-simple-wysiwyg";
import { clearnbsp } from "../../utils";

export default function HTMLArea({
  collector,
  name,
  label,
  multiline,
  disabled,
  hr,
  onTestValue,
  containerStyle = {},
  containerRef,
  inputStyle = {},
  ...props
}) {
  const [text, setText] = useState(() => collector.get(name));
  const [error, setError] = useState();

  collector.resetCallback(name, (value) => setText(value));
  collector.errorCallback(name, (value) => setError(value));

  const styles = useTheme(dynamicStyle);



  return (
    <div
      style={{
        ...styles.container,
        ...containerStyle,
        ...(!!disabled ? styles.disabled : {}),
        ...inputStyle,
        ...(error != null ? styles.error : {}),
      }}
    >

      <DefaultEditor 
        containerProps={{ style: { resize: 'vertical',backgroundColor:'#f00' } }}

        value={text}
        onChange={({ target }) => {
          collector.set(name, clearnbsp(target.value), setText, onTestValue)
        }}
        style={{ flex: 1 }}
        className="asdasdasd"
      />


      {/* <textarea
        {...props}
        onChange={(event) =>
          collector.set(name, event.target.value, setText, onTestValue)
        }
        value={text}

        onInput={(event) => {
          event.target.style.height  = "5px";
          event.target.style.height = (event.target.scrollHeight)+"px";
        }}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        style={{
          ...styles.input,
          ...(!!disabled ? styles.disabled : {}),
          ...inputStyle,
          ...(error != null ? styles.error : {}),

        }}
      /> */}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    container: {
      flexDirection: "row",
      backgroundColor: Colors.neutral4,
      borderRadius: 10,
      paddingHorizontal: 16,
    //  display: "flex",
    },
    input: {
      resize: 'none',
      ...Fonts.subhead1,
      padding: 0,
      width: "100%",
      justifyContent: "center",
      color: Colors.neutral1,
      backgroundColor: Colors.neutral4,
      borderWidth: 0,
      borderRadius: 10,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 8,
      paddingBottom: 8,
      // display: "flex",
      outlineColor: Colors.neutral3,
      overflow: 'hidden'
    },
    disabled: {
      color: Colors.neutral2,
    },
    error: {
      backgroundColor: Colors.accent3,
      outline: `1px solid ${Colors.accent2}`
    },
  };
};
