import { useTheme } from "../../constants/theme";
import Appbar from "../../components/Appbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCreateCourseOptions,
  getArticle,
  createArticle,
  removeArticle,
  errorHandler,
} from "../../api";
import { useEffect, useRef } from "react";
import form, {
  Emitter as articleEmmiter,
} from "./../../forms/createNewArticle";
import { enrichArticle } from "../../utils";
import { useState } from "react";
import SelectPicker from "../../components/Form/SelectPicker";
import TextInput from "../../components/Form/TextInput";
import MainLesonBlock from "../../components/BlockMaker/MainLesonBlock";
import { Plus, Trash } from "react-feather";
import { Settings } from "../../stores/Settings";
import PreView from "../../components/PreView/PreView";

export default function ArticlesEditorPage() {
  const styles = useTheme(dynamicStyle);
  const mainBlock = useRef();

  const params = useParams();
  const [ready, setReady] = useState(false);
  const [state, setState] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Promise.all([
      getCreateCourseOptions(),
      params.id !== "new"
        ? getArticle(params.id)
        : createArticle({ title: "Без названия", blocks: [], preview: [] }),
    ]).then(([options, course]) => {
      form.reset({
        ...enrichArticle(course),
        _options: options,
      });

      if (params.id === "new") {
        params.id = course._id;
        window.history.replaceState(
          null,
          window.document.title,
          `/articles/${course._id}`
        );
      }

      setReady(true);
    });

    return () => form.reset();
  }, []);

  useEffect(() => {
    return articleEmmiter.on(`*`, (val, key) => {
      setState({ ...form.link() });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wantRemoveLesson = () => {
    Settings.emit("show_dialog", {
      title: "Удаление урока",
      text: "Вы действительно хотите удалить эту статью, это действие необратимое.",
      acceptText: "Не удалять",
      cancelText: "Удалить",
      onCancel: remove,
    });
  };

  const remove = async () => {
    try {
      await removeArticle({ _id: form.get("_id") });
      navigate(-1);
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <>
      <Appbar />

      <div style={styles.listContiner}>
        <div style={styles.listWrapper}>
          <div style={styles.title}>{state.title}</div>
          <div>
            <div style={styles.label}>Название курса</div>
            <TextInput
              containerStyle={styles.textInputontainerStyle}
              name={"title"}
              collector={form}
            />

            <div style={styles.label}>Категоия</div>

            <SelectPicker
              containerStyle={styles.textInputontainerStyle}
              name={"category"}
              collector={form}
              title={"Категория"}
              items={Object.keys(form.get("_options.categories", {})).map(
                (key) => {
                  return {
                    value: key,
                    label: form.get("_options.categories", {})[key],
                  };
                }
              )}
            />

            <div style={styles.label}>Обложка</div>

          
              <MainLesonBlock
                containerStyle={styles.mainBlockcontainerStyle}
                buttonText={"Добавить обложку"}
                form={form}
                emitter={articleEmmiter}
                formField={"preview"}
                blocks={["photo", "video"]}
                formType={"createNewCourse"}
                firstDisableAction={{ showDialog: true }}
                showAddButton={state?.preview?.length === 0}
                selected={state?.preview?.length === 0}
              />
            

            <div style={styles.label}>Контент</div>

          <MainLesonBlock
              containerStyle={styles.mainBlockcontainerStyle}
              buttonText={"Добавить блок"}
              form={form}
              emitter={articleEmmiter}
              formField={"blocks"}
              formType={"createNewCourse"}
              ref={mainBlock}
              selected={state?.preview?.length > 0}
            />
          </div>
        </div>
      </div>

      <div style={styles.footer}>
        <div style={styles.footerWrapper}>
          <div onClick={wantRemoveLesson} style={styles.removeButton}>
            <Trash style={styles.addIcon} />
          </div>
        </div>
        <div style={styles.footerWrapper}>
          <div
            onClick={(e) => mainBlock.current.show(void 0, e)}
            style={styles.addButton}
          >
            <Plus style={styles.addIcon} />
          </div>
        </div>
      </div>

      <PreView form={form} emitter={articleEmmiter} type={"article"} />
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    removeButton: {
      backgroundColor: Colors.accent2,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 52,
      width: 52,
      borderRadius: 25,
      cursor: "pointer",
    },
    footerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 8,
    },
    addIcon: {
      color: Colors.white,
    },
    addButton: {
      backgroundColor: Colors.color1,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 52,
      width: 52,
      borderRadius: 25,
      cursor: "pointer",
    },
    footer: {
      position: "fixed",
      right: 40,
      bottom: 32,
    },
    mainBlockcontainerStyle: {
      marginBottom: 16,
    },
    textInputontainerStyle: {
      marginBottom: 16,
    },
    inputStyle: {
      // minHeight: 160,
      //borderRadius:8,
      //overflow:'hidden'
    },
    label: {
      ...Fonts.subhead1,
      color: Colors.neutral2,
      marginBottom: 8,
    },
    title: {
      ...Fonts.title1,
      color: Colors.neutral1,
      marginBottom: 24,
    },
    listWrapper: {
      width: 500,
    },
    listContiner: {
      display: "flex",
      flex: 1,
      flexDirection: "conumn",
      width: "100%",
      justifyContent: "center",
    },
    addButtonStyle: {
      ...Fonts.subhead2,
      backgroundColor: Colors.color1,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 16,
      color: Colors.white,
      cursor: "pointer",
    },
    exitButtonStyle: {
      ...Fonts.subhead2,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 16,
      color: Colors.neutral1,
      cursor: "pointer",
      marginLeft: 16,
    },
    submenu: {
      justifyContent: "center",
      display: "flex",
    },
    appItemFlex1: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
    },
    appbar: {
      marginLeft: 8,
      marginRight: 8,

      height: 78,
      display: "flex",
      alignItems: "center",
    },
  };
};
