import { useEffect, useState } from "react";
import { X, Link, Twitter, Instagram, Facebook } from "react-feather";
import { errorHandler, parseSoclialLink } from "../../../api";
import { getClasses } from "../../../constants/theme";

const icons = {
  twitter: Twitter,
  vk: Link,
  site: Link,
  facebook: Facebook,
  google: Link,
  instagram: Instagram,
  odnoklassniki: Link,
  telegram: Link,
  viber: Link,
  whatsapp: Link,
};

export default function SocialInput({
  scroll,
  form,
  formDisabled,
  name,
  text,
  inputStyle,
  ...props
}) {
  const classes = getClasses(dynamicStyle);

  const [state, setState] = useState({
    loading: false,
    ...form.get(name, {}),
  });
  const [error, setError] = useState();



  const checkSocialType = async (event) => {
    if (!event.target.value) return;

    setState({
      ...state,
      loading: true,
    });

    try {
      const res = await parseSoclialLink(event.target.value);

      setState({
        ...state,
        ...res,
        loading: false,
      });
      setError(void 0)

    } catch (err) {
      errorHandler(err);
      setError(err)
      setState({
        ...state,
        loading: false,
      });
    }
  };

  useEffect(() => {
    form.errorCallback(`${name}.link`, (value) => setError(value));
  },[])

  useEffect(() => {
    form.silentSet(name, state);
  }, [state, form, name]);

  //   useEffect(() => {
  //     setState({
  //       ...form.get(name, {}),
  //       loading: false,
  //     });
  //   }, [form, name]);

  const Icon = icons[state.type];

  return (
    <div className={classes("socialItemWrapper", error ? 'error' : void 0)}>
      <div className={classes("socialItem")}>
      {Icon &&<div onClick={props.removeItem} className={classes("removeWrapper")}>
          <Icon />
        </div>}
        <input
          value={state.link}
          onBlur={checkSocialType}
          onChange={(event) => {
            setState({
              ...state,
              link: event.target.value,
            });
          }}
          className={classes("input")}
        />

         <div
          onClick={props.removeItem}
          className={classes("removeWrapper", "remove")}
        >
          <X size={22} className={classes("removeIcon")} />
        </div>
      </div>
      {state.title !== undefined && (
        <div className={classes("title")}>{state.title}</div>
      )}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    error: {
        backgroundColor: Colors.accent3,
        outline: `1px solid ${Colors.accent2}`
    },
    remove: {
      marginRight: 8,
    },
    removeWrapper: {
      display: "flex",
      alignSelf: "center",
      flex: 0,
      // alignSelf: "center",
      // position: "relative",
      top: 6,
      right: 6,
    },
    removeIcon: {
      color: Colors.neutral2,
      fontSize: 14,
    },
    title: {
      ...Fonts.footnote,
      color: Colors.neutral2,
      marginBottom: 8,
      //marginTop: -8,
    },
    socialItemWrapper: {
      backgroundColor: Colors.neutral4,
      borderRadius: 10,
      padding: 8,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: 8
    },
    indicator: {
      color: Colors.neutral1,
    },
    socialItem: {
      flexDirection: "row",
      alignItems: "flex-start",
      display: "flex",
      flex: 1,
    },
    socialIcon: {
      height: 30,
      width: 40,
      marginLeft: 8,
      marginRight: 8,
      marginTop: 8,
      alignSelf: "flex-start",
    },
    input: {
      ...Fonts.subhead1,
      padding: 0,
      // width: "100%",
      minHeight: 36,
      justifyContent: "center",
      color: Colors.neutral1,
      backgroundColor: Colors.neutral4,
      borderWidth: 0,
      borderRadius: 10,
      paddingLeft: 8,
      paddingRight: 8,
      display: "flex",
      outlineColor: Colors.neutral3,
      flex: 1,
    },
    inputMultilineControl: {
      height: "auto",
      paddingTop: 12,
      paddingBottom: 12,
    },
    inputNoLabelControl: {
      width: "100%",
    },
    disabled: {
      color: Colors.neutral2,
    },
  };
};
