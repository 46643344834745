import { useEffect, useRef, useState } from "react";
import { ArrowLeft, Plus, Trash } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import MainLesonBlock from "../../../components/BlockMaker/MainLesonBlock";
import FormErrorContainer from "../../../components/FormErrorContainer";
import HelpSection from "../../../components/HelpSection";
import { useTheme } from "../../../constants/theme";
import { Settings } from "../../../stores/Settings";
import { findError2 } from "../../../utils";
import courseForm, {
  Emitter as courseEmiter, validateHometask,
} from "./../../../forms/createNewCourse";
import PreView from "../../../components/PreView/PreView";

export default function AddHometask() {
  const styles = useTheme(dynamicStyle);
  const navigate = useNavigate();
  const mainBlock = useRef();
  const params = useParams();
  const [valid, setValid] = useState({ error: null });

  const formField = params.type || "lessons";
  const lessonIndex = params.index || 0;
  const hometaskIndex = params.hindex || 0;
  const id = params.id;
  const formDisabled = false;

  useEffect(() => {
    return courseEmiter.on(`*`, (val, key) => {
      const v = validateHometask(`${formField}.${lessonIndex}.hometasks.${hometaskIndex}`);
      setValid(v);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wantRemoveHometask = () => {
    Settings.emit("show_dialog", {
      title: "Удаление задания",
      text: "Вы действительно хотите удалить это задание, это действие необратимое.",
      acceptText: "Не удалять",
      cancelText: "Удалить",
      onCancel: removeHometask,
    });
  };

  const removeHometask = () => {
    const hometasks = courseForm.get(
      `${formField}.${lessonIndex}.hometasks`,
      []
    );
    courseForm.set(
      `${formField}.${lessonIndex}.hometasks`,
      hometasks.filter((l, i) => i !== +hometaskIndex)
    );
    navigate(-1);
  };

  const goBack = () => {
    const hometask = courseForm.link(
      `${formField}.${lessonIndex}.hometasks.${hometaskIndex}`
    );

    if (hometask?.blocks?.length === 0) {
      removeHometask();
    } else {
      navigate(-1);
    }
  };

  const goToPreview = () => {
    navigate(`/course/${id}/lessons/${formField}/${lessonIndex}/hometask/${hometaskIndex}/preview`)
  }

  return (
    <>
      <div style={styles.title}>
        <ArrowLeft onClick={goBack} style={styles.backIcon} size={32} />
        Домашнее задание
      </div>
      <HelpSection id={"lesson"}>
        Пользователь будет видеть задания в уроке, вы будете видеть кто выполнил
        задания, а кто нет. Так же вы сможете оценивать задания и оставлять
        комментарии.
      </HelpSection>

      {findError2(valid, /blocks/) && (
        <FormErrorContainer>
          Значение блоков не должно быть пустым
        </FormErrorContainer>
      )}

      <div style={styles.label}>Задание</div>
      <MainLesonBlock
        ref={mainBlock}
        disabled={formDisabled}
        buttonText={"Добавить блок"}
        form={courseForm}
        emitter={courseEmiter}
        showAddButton={true}
        formField={`${formField}.${lessonIndex}.hometasks.${hometaskIndex}.blocks`}
      />
      <div style={styles.footer}>
        <div style={styles.footerWrapper}>
          <div onClick={wantRemoveHometask} style={styles.removeButton}>
            <Trash style={styles.addIcon} />
          </div>
        </div>
        <div style={styles.footerWrapper}>
          <div
            onClick={(e) => mainBlock.current.show(void 0, e)}
            style={styles.addButton}
          >
            <Plus style={styles.addIcon} />
          </div>
        </div>
      </div>


      <PreView
        form={courseForm}
        emitter={courseEmiter}
        lessonIndex={lessonIndex}
        formField={formField}
        type={'hometask'}
       // formField={`${formField}.${lessonIndex}.blocks`}
      />
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
    label: {
      ...Fonts.subhead1,
      color: Colors.neutral2,
      marginBottom: 0,
    },
    previewButton: {
      ...Fonts.headline1,
      backgroundColor: Colors.color1,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 48,
      borderRadius: 25,
      color: Colors.white,
      paddingLeft: 64,
      paddingRight: 64,
      marginRight: 16,
      cursor: "pointer",
    },
    addIcon: {
      color: Colors.white,
    },
    removeButton: {
      backgroundColor: Colors.accent2,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 52,
      width: 52,
      borderRadius: 25,
      cursor: "pointer",
    },
    addButton: {
      backgroundColor: Colors.color1,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 52,
      width: 52,
      borderRadius: 25,
      cursor: "pointer",
    },
    footer: {
      position: "fixed",
      right: 40,
      bottom: 32,
    },
    footerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 8,
    },
  };
};
