import React from "react";
import { getClasses } from "./../constants/theme/index";
import logo from "./../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import Avatar from "./Avatar";
import { Settings } from "../stores/Settings";

export default function Logo(props) {
  const classes = getClasses(dynamicStyle);
  const navigate = useNavigate();
  return (
    <div
      className={classes("container")}
    >
      <img
        src={logo} 
        alt="logo"
        onClick={() => {
          navigate("/");
        }} />

    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {
      cursor: "pointer",

      display: "flex",
      alignItems: "center",

    },
    logoText: {
      ...Fonts.title2,
      color: Colors.neutral1,
      marginLeft: 8,
    },
  };
};
