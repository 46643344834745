import React from "react";
import { getClasses, getTheme } from "../../../constants/theme";
import { getFormCollector } from "../../../stores/core/collector";
import TextArea from "../../Form/TextArea";
import { Plus, RefreshCcw, X } from "react-feather";

export default function BlockHeader2(item, scrollRef, actions, options = {}) {
  const styles = getTheme(dynamicStyle);
  const classes = getClasses(dynamicClasses);

  const form = getFormCollector(
    {
      text: item.value,
    },
    {},
    (k, v) => actions.saveValue(item.id, v)
  );

  return (
    <div className={classes("constainer")}>
      <div className={classes("wrapper")}>
        <div className={classes("topSection")}>
        {options?.disableActions?.changeBlockType !== true && <div onClick={(e) => actions.changeBlockType(item, e)}>
            <RefreshCcw name="plus" size={18} className={classes("iconrefresh")} />
          </div>}
          {!options.hideLabel && (
            <div className={classes("label")}>Заголовок 2</div>
          )}
          {options.disabled !== true && options.disableActions !== true && (
            <div className={classes("actions")}>
              {options?.disableActions?.showDialog !== true && (
                <div onClick={(e) => actions.showDialog(item,e)}>
                  <Plus className={classes("iconRight")} />
                </div>
              )}
              {options?.disableActions?.removeBlock !== true && (
                <div onClick={() => actions.removeBlock(item)}>
                  <X className={classes("iconRight")} />
                </div>
              )}
            </div>
          )}
        </div>
        <TextArea
          disabled={options.disabled}
          containerStyle={styles.inputContainerStyle}
          inputStyle={styles.input}
          collector={form}
          name={"text"}
          containerRef={scrollRef}
          placeholder={"Заголовок 2"}
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    inputContainerStyle: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    input: {
      ...Fonts.headline1,
      color: Colors.neutral1,
    },
  };
};

const dynamicClasses = (Colors, Fonts) => {
  return {
    icon: {
      color: Colors.neutral2,
      fontSize: 18,
    },
    actions: {
      display: "flex",
      flexDirection: "row",
    },

    constainer: {
      marginTop: 8,
      backgroundColor: Colors.neutral4,
      borderRadius: 10,
    },
    wrapper: {
      marginLeft: 12,
      marginRight: 12,
      paddingTop: 8,
      paddingBottom: 8,
    },

    label: {
      ...Fonts.subhead2,
      color: Colors.neutral2,
      flex: 1,
      marginLeft: 8,
      marginRight: 8,
    },
    topSection: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 8,
      display: "flex",
    },
    iconLeft: {
      color: Colors.neutral2,
      fontSize: 22,
      marginRight: 2,
    },
    iconRight: {
      color: Colors.neutral2,
      fontSize: 22,
      marginLeft: 2,
    },
    iconrefresh: {
      color: Colors.neutral2,
      fontSize: 16,
      marginLeft: 0,
    },
  };
};
