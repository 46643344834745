
import React, { useState, useEffect, useImperativeHandle, useRef, useCallback } from "react";
import { useTheme } from "../constants/theme";
import { Settings } from "../stores/Settings";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from "../hooks/useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import Button from "./Button";
import { X } from "react-feather";

function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}


const aspects = {
    'original': void 0,
    'derivative': void 0,
    '1:1': 1 / 1,
    '16:9': 16 / 9,
    '4:3': 4 / 3,
    '3:2': 3 / 2
}

export default function ImageCropForm(props) {
    const styles = useTheme(dynamicStyle);
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)

    const [completedCrop, setCompletedCrop] = useState(PixelCrop)

    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(undefined)
    const [aspectStr, setAspectStr] = useState('original');

    const [file, setFile] = useState(void 0);
    const [crop, setCrop] = useState();

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    useEffect(() => {
        return Settings.on('cropThisFile', f => {
            setFile(f)
        })
    }, []);

    const onImageLoad = useCallback((e) => {
        //if (aspect) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, width / height))
        // }
    }, [imgRef?.current?.currentUrl])

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(16 / 9)
            setCrop(centerAspectCrop(width, height, 16 / 9))
        }
    }

    const changeAspect = (a) => {
        setAspectStr(a);
        if (a === 'derivative'){
            setAspect(void 0);
            return;
        }
        const aspect = aspects[a];

        if (aspect === undefined) {
            const { width, height } = imgRef.current;
            setCrop(centerAspectCrop(width, height, width / height))
        } else {
            const { width, height } = imgRef.current;
            setAspect(aspect)
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }



    const close = () => {
        Settings.emit(`closeCropFile-${file.id}`, {
            event: 'close'
        });
        setFile(void 0);
        setCompletedCrop(void 0);
        setCrop(void 0)
    }
    const save = () => {
        Settings.emit(`closeCropFile-${file.id}`, {
            event: 'save',
            file: previewCanvasRef.current
        });
        setFile(void 0);
        setCompletedCrop(void 0);
        setCrop(void 0);
    }


    if (!file)
        return null;

    return <div style={styles.container}>
        {/* <div style={styles.overlay}></div> */}
        <div style={styles.form}>
            {/* <div style={styles.header}>
                Обрезать изображение
                <X onClick={close} size={18} />
            </div> */}
            <div style={styles.body}>
                <div style={styles.crop}>
                    <ReactCrop
                        crop={crop}
                        onChange={(c, p) => {
                            setCrop(p)
                        }}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}

                    >
                        <img
                        crossorigin="anonymous"
                            onLoadCapture={onImageLoad}
                            ref={imgRef} style={styles.image} src={file.file} />
                    </ReactCrop>
                </div>
                <div style={styles.menu}>
                    <div style={styles.innerMenu}>
                        <div style={styles.action}>
                            <div style={styles.title}>Загрузка фото</div>
                            <div style={styles.subtitle}>Соотношение сторон</div>

                            <Button
                                text={"Исходное"}
                                containerStyle={aspectStr === 'original' ? styles.selectedButton : styles.button}
                                onClick={() => { changeAspect('original') }}

                            />
                            <Button
                                containerStyle={aspectStr === 'derivative' ? styles.selectedButton : styles.button}
                                text={"Произвольное"}
                                onClick={() => changeAspect('derivative')}

                            />

                            <Button
                                containerStyle={aspectStr === '1:1' ? styles.selectedButton : styles.button}
                                text={"1 : 1"}
                                onClick={() => { changeAspect('1:1') }}
                            />

                            <Button
                                containerStyle={aspectStr === '16:9' ? styles.selectedButton : styles.button}
                                text={"16 : 9"}
                                onClick={() => { changeAspect('16:9') }}

                            />

                            <Button
                                containerStyle={aspectStr === '4:3' ? styles.selectedButton : styles.button}
                                text={"4 : 3"}
                                onClick={() => { changeAspect('4:3') }}

                            />


                            <Button
                                containerStyle={aspectStr === '3:2' ? styles.selectedButton : styles.button}
                                text={"3 : 2"}
                                onClick={() => { changeAspect('3:2') }}

                            />
                        </div>

                        <div>

                            <Button
                                onClick={save}
                                text={"Сохранить"}
                                containerStyle={styles.selectedButton}

                            />
                            <Button
                                onClick={close}
                                text={"Отменить"}
                                containerStyle={styles.button}

                            />
                        </div>


                    </div>
                </div>
            </div>

            {completedCrop && <canvas
                ref={previewCanvasRef}
                style={{
                    border: '1px solid black',
                    objectFit: 'contain',
                    display: 'none',
                    width: completedCrop.width,
                    height: completedCrop.height,
                }}
            />}
            {/* <div style={styles.footer}>
                <Button
                    onClick={close}
                    containerStyle={styles.close}
                    text={"Отменить"}
                />
                <Button
                    onClick={save}
                    containerStyle={styles.save}
                    text={"Сохранить"}
                />
            </div> */}

        </div>
    </div>
}


const dynamicStyle = (Colors, Fonts) => {
    return {
        selectedButton: {
            ...Fonts.headline1,
            color: '#000',
            backgroundColor: Colors.color1,
            display: "flex",
            justifyContent: "center",
            paddingTop: 16,
            paddingBottom: 16,
            borderRadius: 16,
            cursor: "pointer",
            marginTop: 8

        },
        button: {
            color: Colors.color1,
            backgroundColor: 'transparent',
            borderWidth: 2,
            borderColor: Colors.color1,
            borderStyle: 'solid',
            marginTop: 8
        },
        title: {
            ...Fonts.title2,
            color: Colors.neutral5,
            marginBottom: 16
        },
        subtitle: {
            ...Fonts.subhead2,
            color: Colors.neutral5
        },
        action: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column'

        },
        crop: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            //padding: 16
        },
        menu: {
            width: 270,
            height: '100%',
            backgroundColor: '#000',
            display: 'flex',
            flexDirection: 'column',
        },
        innerMenu: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: 32,
        },
        body: {
            display: 'flex',
            flex: 1,
            height: '100%'
        },
        close: {
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
            fontSize: 14,
            backgroundColor: Colors.accent2,
            marginRight: 12
        },
        save: {
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
            fontSize: 14,
        },
        header: {
            ...Fonts.headline1,
            marginBottom: 6,
            display: 'flex',
            justifyContent: 'space-between'
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginTop: 10
        },
        image: {
            width: '100%',
            height: '100vh',
            objectFit: 'contain'
        },
        container: {
            position: 'fixed',
            zIndex: 1,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: 'flex',
            flex: 1,
            // justifyContent: 'center',
            // alignItems: 'center'
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: '#000000af',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 2,
            backgroundColor: '#000000af',
            // padding: '12px 16px',
            flex: 1,
        },
    }
}