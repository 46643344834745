import { getClasses } from "../constants/theme";

export default function FormErrorContainer(props) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes('rulesContainer')}>
      <div className={classes('rulesText')}>{props.children}</div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    rulesContainer: {
        backgroundColor: Colors.accent3,
        borderRadius: 16,
        marginTop: 8,
        marginBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,

        paddingTop: 8,
        paddingBottom: 8,
      },
      rulesText: {
        ...Fonts.subhead1,
        color: Colors.accent1,
      },
  };
};
