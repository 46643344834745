/* eslint-disable no-unused-vars */
export const white = '#000000';
export const black = '#FFFFFF';

export const neutral1 = '#F6F6F5';
export const neutral2 = '#ABB3BA';
export const neutral3 = '#717E8C';
export const neutral4 = '#222222';
export const neutral5 = '#000000';

export const color1 = '#1FCE7E';
export const color2 = '#006A3A';
export const color3 = '#004A29';
export const color4 = '#042918';

export const accent1 = '#F9B23E';
export const accent2 = '#FF6759';
export const accent3 = '#61390A';

export const modal = '#00000066';

export const backgroundLayout = '#111314';
export const red = '#FF4F3F';

export const baseIconColor = '#fff';
export const ripple = '#B3B3B3';
export const disabled = '#B8BDC2';

export const chatBackground = '#111314';
export const myMessageBackground = '#153E55';
export const messageBackground = '#222222';

export const absoluteWhite = '#FFFFFF';
export const absoluteBlack = '#000000';

export const messageSelected = '#0F171B';
export const content = '#17AD50';

export const header = '#25CC8B';