import { getClasses } from "../../../constants/theme";

export default function LessaonItem(props) {
  const { onClick, title, subtitle } = props;
  const classes = getClasses(dynamicStyle);

  return (
    <div
      className={classes("container", props.hasError ? "error" : void 0)}
      onClick={onClick}
    >
      <div className={classes("title")}>{title}</div>
      <div className={classes("subtitle")}>{subtitle}</div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {
      marginTop: 8,
      backgroundColor: Colors.neutral4,
      borderRadius: 10,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 12,
      paddingBottom: 12,
      cursor: "pointer",
    },
    "container:hover": {
      opacity: 0.5,
    },
    error: {
      outline: `2px solid ${Colors.accent2}`,
      backgroundColor: Colors.accent3,
    },
    title: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    subtitle: {
      ...Fonts.footnote,
      color: Colors.neutral2,
    },
  };
};
