import "./App.css";
import Dialog from "./components/Dialog";
import ImageCropForm from "./components/ImageCropForm";
import VideoTrimForm from "./components/VideoTrimForm";
import useStore from "./hooks/useStore";
import Navigation from "./navigation";

window.anonymouslyFramed = true

function App() {
  const ready = useStore();

  if (!ready) return null;

  return (
    <>
      <Navigation />
      <Dialog />
      <ImageCropForm />
      <VideoTrimForm />

    </>
  );
}

export default App;
