import React, { useEffect, useRef, useState } from "react";
import { QRCode } from "react-qr-svg";
import { useNavigate } from "react-router-dom";
import { sendQRUUIDCode } from "../../api";
import Logo from "../../components/Logo";
import { getClasses, useTheme } from "../../constants/theme";
import { Settings } from "../../stores/Settings";
import { UUIDGeneratorBrowser, getClientTitle, wait } from "../../utils";
import Avatar from "../../components/Avatar";
import changeAccount from "../../methods/changeAccount";

export default function ChooseAccount() {
  const classes = getClasses(dynamicStyle);
  const navigate = useNavigate();

  useEffect(() => {
    // if (!Settings.has("authorizationToken")) {
    //   navigate("/auth");
    //   return;
    // }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeAccount = (acc, mode) => {
    if (changeAccount(acc, mode)) {
      navigate("/course");
    }
  }


  return (
    <div className={classes('container')}>
      <div className={classes('wrapper')}>

        <div className={classes('title')}>
          Выберите аккаунт
        </div>
        <div className={classes('subtitle')}>
          Вы являетесь ассистентом у других авторов. Выберите чей курс вы хотите редактировать
        </div>
        <div className={classes('list')}>

          <div className={classes('item')} onClick={() => onChangeAccount(Settings.get('me'), 'org')}>
            <Avatar clId={Settings.get('me')} size={48} />
            <div className={classes('itemWrapper')}>
              <div className={classes('name')}>{getClientTitle(Settings.get('me'))}</div>
              <div className={classes('mode')}>Ваш аккаунт</div>
            </div>
          </div>

          <div className={classes('seporator')}></div>


          {Settings.get('accounts', []).map(acc => {
            return <><div className={classes('item')} onClick={() => onChangeAccount(acc, 'assistant')}>
              <Avatar clId={acc} size={48} />
              <div className={classes('itemWrapper')}>
                <div className={classes('name')}>{getClientTitle(acc)}</div>
                <div className={classes('mode')}>Вы ассистент</div>
              </div>
            </div>
              <div className={classes('seporator2')}></div>
            </>
          })}
        </div>
      </div>

      <div className={classes('logoWrapper')} >
        <Logo />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    subtitle: {
      ...Fonts.body,
      marginLeft: 24,
      marginRight: 24,
      marginBottom: 16
    },
    list: {
      paddingBottom: 20
    },
    wrapper: {
      width: 400,
      backgroundColor: '#fff',
      borderRadius: 16,
      filter:
        "drop-shadow(0px 16px 16px rgba(50, 50, 71, 0.16)) drop-shadow(0px 16px 32px rgba(50, 50, 71, 0.12))",
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 4,
      paddingBottom: 4,
      cursor: 'pointer',
    },
    seporator: {
      marginTop: 8,
      marginBottom: 8,
      height: 1,
      alignSelf: 'stretch',
      background: Colors.neutral3,
      marginLeft: 24,
      marginRight: 24
    },
    seporator2: {
      marginTop: 8,
      marginBottom: 8,
      height: 1,
      alignSelf: 'stretch',
      marginLeft: 24,
      marginRight: 24
    },
    // "item:hover": {
    //   backgroundColor: Colors.neutral4,
    // },
    itemWrapper: {
      marginLeft: 12
    },
    name: {
      ...Fonts.headline1,
      color: Colors.neutral1

    },
    mode: {
      marginTop: 4,
      ...Fonts.subhead1,
      color: Colors.neutral2

    },
    qr: {
      width: 256,
    },
    qrBgColor: Colors.backgroundLayout,
    fgColor: Colors.neutral1,
    logoWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "flex-end",
      position: "absolute",
      bottom: 24,
      right: 16,
    },
    li: {
      ...Fonts.headline1,
      color: Colors.neutral1,
      lineHeight: "32px",
    },
    title: {
      ...Fonts.title2,
      color: Colors.neutral1,
      margin: 24,
      marginBottom: 16
    },
    qrWrapper: {},
    container: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
    },
  };
};
