import { getClasses } from "../../../../../constants/theme";

export default function ViewBlockHeader2({ block, first }) {
  const classes = getClasses(dynamicStyle);
  return (
    <div className={classes("constainer", first ? "first" : void 0)}>
      <div className={classes("label")}>{block?.value}</div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    first: {
      marginTop: 0,
    },
    constainer: {
      marginTop: 8,
      marginBottom: 8,
    },
    label: {
      ...Fonts.headline1,
      color: Colors.neutral1,
      whiteSpace:'pre-wrap'
    },
  };
};
