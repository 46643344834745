import { useNavigate } from "react-router-dom";
import InfinityList from "../../../components/InfinityList";

import { getMyArticles, getMyCourses } from "./../../../api";
import DraftItem from "../components/DraftItem";

export default function DraftList() {
  const navigate = useNavigate();

  const onClick = (item) => {
    navigate(`/articles/${item._id}`);
  }
  return (
    <InfinityList
      renderItem={(item) => <DraftItem onClick={() => onClick(item)} item={item} />}
      fetchData={(param) => getMyArticles({ ...param, mode: "draft" })}
    />
  );
}
