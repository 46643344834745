import { ArrowLeft } from "react-feather";
import { getClasses } from "../../../constants/theme";

import header from './../../../assets/1-light.png';
import { useEffect, useRef, useState } from "react";
import BlockView from "./BlockView/BlockView";
import { Settings } from "../../../stores/Settings";
import CourseForm from './../../../forms/createNewCourse';


export default (props) => {
    const classes = getClasses(dynamicStyle);
    const blockFiled = `${props.formField}.${props.lessonIndex}.blocks`;
    const [blocks, setBlocks] = useState(props.form.link(blockFiled, []));
    const scroll = useRef();
    const [title, setTitle] = useState(props.form.link(`${props.formField}.${props.lessonIndex}.title`))

    useEffect(() => {
        const off1 = props.emitter.on(blockFiled, (b) => {
            setBlocks([...b]);
        });

        const off2 = props.emitter.on(`${props.formField}.${props.lessonIndex}.title`, (t) => {
            setTitle(t);
        });

        return () => {
            off1();
            off2();
        }


    }, [blockFiled, props.emitter]);

    useEffect(() => {
        return Settings.on('block-press', ({ index, event }) => {
            document.getElementById(`b-${index}`).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

            const topP = ~~(event.clientY / event.screenY * 100)

            // console.log('blockPress', topP)
        });
    })


    useEffect(() => {
        document.getElementsByTagName('body')[0].onscroll = function (e, a) {
            scroll.current.scrollTop = window.scrollY;

        };

        return () => document.getElementsByTagName('body')[0].onscroll = void 0;
    }, [])

    return <div>
        <div className={classes('header')}>
            <div className={classes('statusbar')}></div>
            <div className={classes('bar')}>
                {/* <ArrowLeft
                    onClick={() => { }}
                    className={classes("backIcon")}
                    size={24}
                /> */}
                <div className={classes('title')}>Урок {+props.lessonIndex + 1}</div>
                <div></div>
            </div>
            <div className={classes('whitePower')}></div>
        </div>
        <div id={'scroll'} ref={scroll} className={classes('bodyWrapper')}>
            <div className={classes('body')}>
                <div className={classes('courseName')}>{CourseForm.get('title')}</div>
                <div className={classes('lessonName')}>{title}</div>
                {!!props.form.link(`${props.formField}.${props.lessonIndex}.hometasks.0`) && (<div className={classes('hasHomeTask')}>Есть задание</div>)}

                <BlockView blocks={blocks} />
                <div style={{ height: 50 }}></div>
            </div>
        </div>
    </div>
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
    return {
        bodyWrapper: {
            overflow: 'hidden',

            height: 616,
            borderBottomRightRadius: 35,
            borderBottomLeftRadius: 35
        },
        courseName: {
            color: Colors.neutral2,
            ...Fonts.footnote
        },
        lessonName: {
            color: Colors.neutral1,
            ...Fonts.title2,
            marginTop: 4
        },
        hasHomeTask: {
            backgroundColor: Colors.color2,
            ...Fonts.tagline3,
            color: Colors.neutral5,
            display: 'flex',
            width: 89,
            height: 16,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 16,
            marginTop: 8,
            marginBottom: 12
        },
        body: {
            marginLeft: 16,
            marginRight: 16
        },
        title: {
            ...Fonts.title2,
            color: Colors.neutral1,
            marginLeft: 16,


        },
        whitePower: {
            height: 10,
            backgroundColor: Colors.backgroundLayout,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6
        },
        backIcon: {
            color: Colors.content,
            marginRight: 16,
            marginLeft: 16,
            cursor: "pointer",
        },
        bar: {
            height: 60,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'

        },
        statusbar: {
            height: 44
        },
        header: {
            backgroundSize: 'contain',
            backgroundImage: `url(${header})`,
            backgroundColor: Colors.header,
            backgroundSize: 'cover',
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24
        },
    }
}