import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Outlet,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";

import AuthPage from "./../pages/AuthPage/AuthPage";
import CoursesListPage from "./../pages/CoursesListPage/CoursesListPage";
import DraftList from "./../pages/CoursesListPage/SubPages/DraftList";
import ArchiveList from "./../pages/CoursesListPage/SubPages/ArchiveList";
import ForModerationList from "./../pages/CoursesListPage/SubPages/ForModerationList";
import StudyGriupList from "./../pages/CoursesListPage/SubPages/StudyGriupList";
import TemplateList from "./../pages/CoursesListPage/SubPages/TemplateList";
import { errorHandler, getAssistantAccounts, getMe } from "../api";
import CreateNewCourse from "../pages/CreateNewCourse/CreateNewCourse";
import Menu from "../pages/CreateNewCourse/SubPage/Menu";
import StartPage from "../pages/CreateNewCourse/SubPage/StartPage";
import Lessons from "../pages/CreateNewCourse/SubPage/Lessons";
import { Settings } from "../stores/Settings";
import AddLesson from "../pages/CreateNewCourse/SubPage/AddLesson";
import AddHometask from "../pages/CreateNewCourse/SubPage/AddHometask";
import LessonPreview from "../pages/CreateNewCourse/SubPage/LessonPreview";
import HometaskPreview from "../pages/CreateNewCourse/SubPage/HometaskPreview";
import StartPagePreview from "../pages/CreateNewCourse/SubPage/StartPagePreview";
import ChooseAccount from "../pages/AuthPage/ChooseAccount";
import ArticlesListPage from "../pages/ArticlesPage/ArticlesListPage";
import ArticlesEditorPage from "../pages/ArticlesPage/ArticlesEditorPage";

export default function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/accounts" element={<ChooseAccount />} />

          
          <Route path="/articles" element={<ArticlesListPage />} />
          <Route path="/articles/:id" element={<ArticlesEditorPage />} />


          <Route path="/course" element={<CoursesListPage />} />
          

          <Route path="/course/:id" element={<CreateNewCourse />}>
            <Route path="/course/:id/menu" element={<Menu />} />
            <Route path="/course/:id/lessons" element={<Lessons />} />
            <Route
              path="/course/:id/lessons/:type/:index"
              element={<AddLesson />}
            />
            <Route
              path="/course/:id/lessons/:type/:index/preview"
              element={<LessonPreview />}
            />
            <Route
              path="/course/:id/lessons/:type/:index/hometask/:hindex"
              element={<AddHometask />}
            />
            <Route
              path="/course/:id/lessons/:type/:index/hometask/:hindex/preview"
              element={<HometaskPreview />}
            />
            <Route path="/course/:id/start_page" element={<StartPage />} />
            <Route path="/course/:id/start_page/preview" element={<StartPagePreview />} />

          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (Settings.has("authorizationToken")) {
      Promise.all([getMe(), getAssistantAccounts()])
        .then(([res, accounts]) => {
          Settings.memorize("me", res);
          Settings.memorize("accounts", accounts?.data ?? []);

          setReady(true);
        })
        .then(() => {
          if (!Settings.has('account')) {
            navigate("/accounts");

          } else if (location.pathname === "/") {
              navigate("/course");
            }
        })
        .catch((err) => {
          // if (err.exception === "SessionExpiredError") {
          Settings.clear("me");
          Settings.clear("authorizationToken");
          Settings.clear('account');

          // }
          setReady(true);

          navigate("/auth");
          errorHandler(err);
        });
    } else {
      setReady(true);

      navigate("/auth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === "/"]);

  return ready ? <Outlet /> : null;
}
