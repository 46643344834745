import { useEffect, useState, useRef } from "react";
import { getClasses, useTheme } from "../constants/theme";
import { Settings } from "../stores/Settings";
import { getClientTitle, makeImageUrl } from "../utils";
import Logo from "./Logo";
import { useLocation, useNavigate } from "react-router-dom";
import Deauth from "../methods/deauth";
import Avatar from "./Avatar";
import Switch from "./Switch";
import changeAccount from "../methods/changeAccount";


export default function Appbar(props) {
    const classes = getClasses(dynamicStyle);
    const navigate = useNavigate();
    const location = useLocation();
    const sw = useRef();

    const deauth = () => {
        Deauth(navigate);
    };

    const onChangeAccount = async (clId, mode) => {
        if (await changeAccount(clId, mode)) {
            navigate('/')
        }
    }

    return <div className={classes('appbar')} >
        <Logo />
        <div className={classes('appItemFlex1')}>
            <div className={classes('menuWrapper')}>
                <div onClick={() => navigate('/course')} className={classes('menuItem')}>Курсы</div>
                <div onClick={() => navigate('/articles')} className={classes('menuItem')}>Статьи</div>
            </div>
        </div>
        <div className={classes('submenu')} >
            {props.rightSection}
            <div className={classes('profileWrapper')}>
                <Avatar onClick={(e) => sw.current.show(void 0, e)} size={40} clId={Settings.get('account.client')} />
            </div>
        </div>

        <Switch
            itemStyle={classes('itemClass')}
            containerStyle={classes('containerStyle')}
            ref={sw}
            data={[
                ...getModes(classes, onChangeAccount),

                { title: 'Выйти', handler: deauth }

            ]} />
    </div>
}


const getModes = (classes, onClick) => {
    return [
        { component: <Account onClick={onClick} classes={classes} clId={Settings.get('me')} i18n={'Ваш аккаунт'} mode={'org'} /> },
        { component: <div className={classes('label')}></div> },
        ...Settings.get('accounts', []).map(acc => {
            return { component: <Account onClick={onClick} classes={classes} key={acc._id} i18n={'Вы ассистент'} clId={acc} mode={'assistant'} /> }
        })

    ]
}

const Account = ({ clId, mode, classes, onClick, i18n }) => {
    return <div onClick={() => onClick(clId, mode)} className={classes('accItem')}>
        <Avatar size={48} clId={clId} />
        <div className={classes('accItemWrapper')}>
            <div className={classes('accItemTitle')}>{getClientTitle(clId)}</div>
            <div className={classes('accItemMode')}>{i18n}</div>
        </div>
    </div>
}



const dynamicStyle = (Colors, Fonts, params = {}) => {
    return {
        menuWrapper: {
            flexDirection:'row',
            display: 'flex',
            marginLeft:24
        },
        menuSelected:{
            color: Colors.color1,
        },
        menuItem: {
            ...Fonts.subhead2,
            paddingLeft: 28,
            paddingRight: 28,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 16,
            color: Colors.neutral1,
            cursor: "pointer",
        },
        accItemWrapper: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 8
        },
        accItemTitle: {
            ...Fonts.headline1,
            color: Colors.neutral1,
        },
        accItemMode: {
            ...Fonts.subhead1,
            color: Colors.neutral2,
        },
        accItem: {
            width: 217,
            display: 'flex',
            flexDirection: 'row',
            height: 48,
            alignItems: 'center',
            marginTop: 8,
            marginBottom: 8,

            cursor: 'pointer'

        },
        // "accItem:hover": {
        //     backgroundColor: Colors.neutral4,
        // },
        label: {

            height: 1,
            alignSelf: 'stretch',
            background: Colors.neutral3,

        },
        containerStyle: {
            minWidth: '215px!important',
            overflow: 'hidden',
            padding: '24px!important',
            paddingTop: '16px!important',
            paddingBottom: '16px!important'



        },
        itemClass: {
            textAlign: 'right',
        },
        profileWrapper: {
            marginLeft: 16,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        addButtonStyle: {
            ...Fonts.subhead2,
            backgroundColor: Colors.color1,
            paddingLeft: 28,
            paddingRight: 28,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 16,
            color: Colors.white,
            cursor: "pointer",
        },
        exitButtonStyle: {
            ...Fonts.subhead2,
            paddingLeft: 28,
            paddingRight: 28,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 16,
            color: Colors.neutral1,
            cursor: "pointer",
            marginLeft: 16,
        },
        submenu: {
            justifyContent: "center",
            display: "flex",
            alignItems: 'center'
        },
        appbar: {
            marginLeft: 8,
            marginRight: 8,

            height: 78,
            display: "flex",
            alignItems: "center",
        },
        appItemFlex1: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
        },
    }
}